<template>
  <component :is="tag" :href="href" @click.prevent="setAppPath(path)">
    <slot />
  </component>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

import { RawLocation } from 'vue-router'
import ModuleManagerApp from '@/modules/moduleManagerApp'
import { setAppPath, urlPathPrefix } from '@/pages/app/appStateRouter'

@Component({
  components: {}
})
export default class VAppRouterLink extends Vue {
  @Prop({ type: Object, required: true }) readonly to!: RawLocation

  // tag type
  @Prop({ type: String, required: false, default: 'a' }) readonly tag!: string

  public get path() {
    const path = ModuleManagerApp.getPath(this.to)
    return (path === '/') ? '' : path
  }

  public get href() {
    return urlPathPrefix + this.path
  }

  public setAppPath(path: string) {
    setAppPath(path)
  }
}
</script>

<style lang="scss">
</style>
