import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { connectFirestoreEmulator, DocumentSnapshot, getFirestore } from 'firebase/firestore'
// import 'firebase/messaging'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID
}

// if (process.env.NODE_ENV === 'development') firebase.firestore.setLogLevel('debug')

// const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {})
// const client = new EchoServiceClient('http://myapi.com')
// enableDevTools([client])

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const storage = getStorage(app)
const functions = getFunctions(app, 'europe-west3')
const auth = getAuth(app)
// const messaging = firebase.messaging()
auth.languageCode = 'en'

console.log('VUE_APP_FIREBASE_ENV', process.env.VUE_APP_FIREBASE_ENV)

if (
  process.env.VUE_APP_FIREBASE_ENV === 'emulator'
  // &&
  // (typeof window === 'undefined' ||
  //   ['localhost', '127.0.0.1', process.env.VUE_APP_FIREBASE_EMULATOR_DOMAIN].includes(window.location.hostname))
) {
  // Note that the Firebase Web SDK must connect to the WebChannel port
  console.warn('using firebase emulator')
  console.log(firebaseConfig)
  // firebase.firestore.setLogLevel('debug')
  connectAuthEmulator(auth, `http://${process.env.VUE_APP_FIREBASE_EMULATOR_DOMAIN}:9099/`)

  connectFunctionsEmulator(functions, `${process.env.VUE_APP_FIREBASE_EMULATOR_DOMAIN}`, 5001)
  // functions.useFunctionsEmulator(`//${process.env.VUE_APP_FIREBASE_EMULATOR_DOMAIN}:5001`)
  connectStorageEmulator(storage, `${process.env.VUE_APP_FIREBASE_EMULATOR_DOMAIN}`, 9199)
  connectFirestoreEmulator(db, `${process.env.VUE_APP_FIREBASE_EMULATOR_DOMAIN}`, 5002)
  // db.useEmulator('localhost', 8080)
  // db.settings({
  //   host: process.env.VUE_APP_FIREBASE_EMULATOR_DOMAIN + ':5002',
  //   ssl: false,
  //   experimentalForceLongPolling: true // to fix cypress. todo may be changed to experimentalAutoDetectLongPolling
  // })
}

export { app, storage, functions }
// export { firebase, app, storage, functions, messaging }

export class DocNotFound extends Error {
  constructor(docSnap: DocumentSnapshot) {
    super(`Document ${docSnap.ref.path} was not found`)
  }
}
