import { UserPrivilegeIdDB } from '@/types/typeUser'

export const SUPER_ADMIN = 'SUPER_ADMIN'

export const viewPrivileges: {
  BILLING_VIEW: UserPrivilegeIdDB
  CODE_ACTIVATE_VIEW: UserPrivilegeIdDB
  CODE_LIST_VIEW: UserPrivilegeIdDB
  // CODE_PURCHASE: UserPrivilegeIdDB
  USER_MANAGE_VIEW: UserPrivilegeIdDB
  CATEGORY_MANAGE_VIEW: UserPrivilegeIdDB
  MASTERDATA_MANAGE_VIEW: UserPrivilegeIdDB
  MODULES_MANAGE_VIEW: UserPrivilegeIdDB
  APP_CONFIG_VIEW: UserPrivilegeIdDB
  BACKEND_CONFIG_VIEW: UserPrivilegeIdDB

  STATISTICS_READ_VIEW: UserPrivilegeIdDB
  WIDGETS_MANAGE_VIEW: UserPrivilegeIdDB

  DASHBOARD_VIEW: UserPrivilegeIdDB
} = {
  CODE_ACTIVATE_VIEW: 'activate-echo-code:view',
  CODE_LIST_VIEW: 'manage-echo-codes:view',

  BILLING_VIEW: 'config-billing:view',
  // CODE_PURCHASE: 'code:purchase',
  CATEGORY_MANAGE_VIEW: 'manage-categories:view',
  MASTERDATA_MANAGE_VIEW: 'config-masterdata:view',
  MODULES_MANAGE_VIEW: 'manage-modules:view',
  WIDGETS_MANAGE_VIEW: 'manage-widgets:view',

  USER_MANAGE_VIEW: 'config-user:view',
  APP_CONFIG_VIEW: 'config-app:view',
  BACKEND_CONFIG_VIEW: 'config-backend:view',

  STATISTICS_READ_VIEW: 'statistics:read',

  DASHBOARD_VIEW: 'dasboard:view'
}

export const dbPrivileges: {
  ANY: UserPrivilegeIdDB
  TOS_ACCEPT: UserPrivilegeIdDB

  USER_READ: UserPrivilegeIdDB
  USER_WRITE: UserPrivilegeIdDB

  ASID_READ: UserPrivilegeIdDB
  ASID_WRITE: UserPrivilegeIdDB

  CONFIG_READ: UserPrivilegeIdDB
  CONFIG_WRITE: UserPrivilegeIdDB

  CATEGORIES_READ: UserPrivilegeIdDB
  CATEGORIES_WRITE: UserPrivilegeIdDB

  MASTERDATA_WRITE: UserPrivilegeIdDB

  MODULES_READ: UserPrivilegeIdDB
  MODULES_WRITE: UserPrivilegeIdDB

  CONTACT_READ: UserPrivilegeIdDB
  CONTACT_WRITE: UserPrivilegeIdDB
} = {
  ANY: 'any',
  TOS_ACCEPT: 'config:write',

  USER_READ: 'user:read',
  USER_WRITE: 'user:write',

  ASID_READ: 'code:read',
  ASID_WRITE: 'code:write',

  CONFIG_READ: 'config:read',
  CONFIG_WRITE: 'config:write',

  CATEGORIES_READ: 'category:read',
  CATEGORIES_WRITE: 'category:write',

  MASTERDATA_WRITE: 'masterdata:write',

  MODULES_READ: 'modules:read',
  MODULES_WRITE: 'modules:write',

  CONTACT_READ: 'contact:read',
  CONTACT_WRITE: 'contact:write'
}
