import firebase from 'firebase/compat/app'
import RecordMeta from '@/types/typeRecordMeta'
import { encodeEmailToKey } from './encodeEmailToKey'

export default class RecordMetaHelper {
  public static async updateMeta(
    docRef: firebase.firestore.DocumentReference,
    type: string = 'updated',
    message?: string
  ) {
    //  docRef.update(this.getUpdateMetaInstructions())
  }

  // todo dont save update log in same record
  public static getUpdateMetaInstructions(
    serverTimestamp: () => firebase.firestore.FieldValue,
    increment: (number: number) => firebase.firestore.FieldValue,
    userEmail: string
  ) {
    // const updateLog: UpdateLog = {
    //   date: new Date(),
    //   type,
    //   message,
    //   user: (await currentUser).id
    // }
    const docMeta: Partial<RecordMeta> = {
      dateUpdated: serverTimestamp(),
      modifiedByUserId: encodeEmailToKey(userEmail),
      revisions: increment(1)
    }

    return {
      '_meta.dateUpdated': docMeta.dateUpdated,
      '_meta.modifiedByUserId': docMeta.modifiedByUserId,
      '_meta.revisions': docMeta.revisions
      // '_meta.updateLog': firebase.firestore.FieldValue.arrayUnion(updateLog)
    }
  }

  // update also in CF_helpers
  public static getNewMetaInstructions(serverTimestamp: () => firebase.firestore.FieldValue, userEmail: string) {
    const _meta: RecordMeta = {
      dateUpdated: serverTimestamp(),
      dateCreated: serverTimestamp(),
      modifiedByUserId: encodeEmailToKey(userEmail),
      createdByUserId: encodeEmailToKey(userEmail),
      revisions: 0
    }

    return {
      _meta
    }
  }
}
