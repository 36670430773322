import RecordMeta from '@/types/typeRecordMeta'
import { CategoryID } from '@/types/typeCategory'
import { locale, LocalizedField } from '@/types/typeI18n'
import { asidID, assetAttributeValue, IdentifierValue, IdentifierValues } from '@/types/typeAsid'
import { TenantID } from '@/types/typeTenant'
import { BaseDB, hasSeenUnseenState, Statistics } from '@/types/typeBase'
import { hasDBid, objectID } from '@/types/typeGeneral'
import { ExpandedWidgetDesignDefinition, TileWidgetDesignDefinition, WidgetDesignDefinition } from './ci/typeCiModule'

export const ALL_MODULE_TYPE_NAMES = [
  'Form',
  'File',
  'Ci',
  'Html',
  'Script',
  'Custom',
  'I18n',
  'Protection',
  // 'Notificat,ion',
  'Data',
  'Service',
  'Link'
] as const

export type ElementID = string

export type ModuleType = typeof ALL_MODULE_TYPE_NAMES[number] | 'Abc' | 'Session' // Abc is the template

export type PublishingState = 'draft' | 'published' | 'archived' | 'deleted'

export interface hasModuleType {
  readonly type: ModuleType
}

export type GroupID = string

// design considerations:
//   - easily see what data is public
//   - differentiate beween base and module specific data
//   - differentiate between data that is stored in the DB and data that is only used in the app
//   - appData is what is present in the app

/// 🟧 Module
export interface BaseModulePublicData {
  type: ModuleType
}
export interface ModuleStatistics {
  rc: number // active response count
  drc: number // deleted response count
  crc: number // created response count
}
export interface BaseModulePrivateData extends BaseDB {
  activated: boolean
  _computed: {
    responseCount: number
    deletedResponseCount: number
    createdResponseCount: number
    statistics: Statistics<ModuleStatistics>
  }
  _meta: RecordMeta
}

export interface BaseModuleDB extends BaseModulePrivateData {
  public: BaseModulePublicData
}

/// 🟨 Group
// 'hidden' hides the widget from the app but the content is still accessible via the URL
export type GroupDisplayType =
  | 'inline'
  | 'button'
  | 'tile-half'
  | 'tile-full'
  | 'tile-auto'
  | 'preview-single'
  | 'hidden' // preview-single is inline, but with default styles if group is button or tile. Like if you clicked the tile

export interface BaseGroupPublicData {
  title: LocalizedField
  subtitle: LocalizedField
  description: LocalizedField
  pageID: string // the page this group is on
  display: {
    displayType: GroupDisplayType
    imageUrl: LocalizedField
    tintMode: 'partial' | 'global' | 'none' // image overlay color
    designTile: WidgetDesignDefinition & TileWidgetDesignDefinition
    designExpanded: WidgetDesignDefinition & ExpandedWidgetDesignDefinition
  }
  order: number
  // wether this group has a widget or is for grouping only. Note that some modules only support backgroundVue, which is an independent concept
  groupType: 'group-type_widget' | 'group-type_group' // widget-type_widget: widget is a widget, widget-type_group: widget is a group
}

export interface BaseGroupDB extends BaseDB {
  name: string
  protectionGroupID: string // wether this group is protected by pw
  publishingState: PublishingState
  public: BaseGroupPublicData
}

/// 🟡 Group AppData
export type BaseGroupAppData = BaseGroupPublicData & hasDBid

/// 🟩 Element
export interface BaseElementPublicData {
  order: number
  groupID: GroupID
}
export interface ElementStatistics {
  rc: number // active response count
  drc: number // deleted response count
  crc: number // created response count
}

export interface ElementReference {
  categoryIDs: {
    c1: Array<CategoryID>
    c2: Array<CategoryID>
    c3: Array<CategoryID>
  }
  asidIDs: Array<objectID>
  identifierValues: IdentifierValues // {c1:[], c2:[], ...}
}

export interface BaseElementPrivateData extends BaseDB {
  name: string
  publishingState: PublishingState
  reference: ElementReference
  _computed: {
    queryCategoryIDs: Array<CategoryID> // categoryIDs used for queriing elements for asid. Optimized to use cats which are used in less elements
    responseCount: number
    deletedResponseCount: number
    createdResponseCount: number
    statistics: Statistics<ElementStatistics>
  }
  _meta: RecordMeta
}

export interface BaseElementDB extends BaseElementPrivateData {
  public: BaseElementPublicData
}

export type ElementWithTypeAndID = BaseElementDB & {
  id: string
  type: ModuleType
}

/// 🟢 Element AppData
export type BaseElementAppData = BaseElementPublicData & hasDBid

/// 🟦 Response

/**
 * During a session (navigation of the App without reload) all RSPs are assigned one responseSession
 * E.g. a Form with 3 Elements and therefore responses will be assigned the same responseSession
 * The same responseTransactionID is assigned for simulataneously created responses. Eg. a form with 3 elements
 */
export type BaseResponseDB = BaseDB &
  hasSeenUnseenState & {
    responseSession: objectID
    publishingState: PublishingState
    categoryIDs: CategoryID[]
    asidIdentifiers: IdentifierValue
    assetAttributeValues: assetAttributeValue
    backendData: {
      // todo this might be obsolete in the future as in the service module already almost all reponse data is modifyable from backend
      // only this part is modifyable in the backend + data may also be modifieable
      acknowledge: boolean
      note: string
    }
    public: BaseResposePublicData
  }

// eslint-disable-next-line @typescript-eslint/no-empty-interface
// variable data per module type
export interface BaseResponseData { }

// part of RSP coming from the app, might be identical to BaseResponseData
export interface BaseAddResponseRequestData { }

export interface BaseResposePublicData {
  data: BaseResponseData // data is quasi equivalent to public, since this is data coming from the client => has now been moved to 'public' key for consitency
  responseTransactionID: objectID // a unique id used for all responses which are issued together. e.g. a form with 3 elements will have 3 responses with the same responseTransactionID
  asidID: asidID
  tenantID: TenantID
  elementID: ElementID
  locale: locale // the locale that was active in the app when the response was created
}

/// 🔵 Response AppData
// response data that goes to the app. It may be different than the data stored in the DB
export type BaseResponseAppData = BaseResposePublicData &
  hasDBid & {
  // also return the elements public data, as it might be required to display the response.
  // E.g. for form responses if the element is archived, but we still want to render the response
  // appData_: BaseModuleGroupAppData
  }

/// 🟪 Response Item
/**
 * Response items are childs of responses. E.g. response to a response.
 * In Service module responses are incidents and responseItems are individual incident events (messages, file uploads,...)
 */
export interface BaseResponseItemPublicData {
  responseID: string // links to the response
  responseTransactionID: objectID
  // asidID: asidID // removed as not every response might be assigned to an asid. e.g. incident for service module
  tenantID: TenantID
  // elementID: ElementID // removed as this might change when response is assigned to tother element
  locale: locale // the locale that was active in the app when the response was created
}

export type BaseResponseItemDB = BaseDB &
  hasSeenUnseenState & {
    responseSession: objectID
    publishingState: PublishingState
    public: BaseResponseItemPublicData
  }

/// 🟣 ResponseItem AppData
export type BaseResponseItemAppData = hasDBid

/// 🟠 AppData
export interface BaseModuleGroupAppData {
  // postfix '_' denotes fields which are filled in CF before sending to app
  public: BaseModulePublicData
  elements: Array<BaseElementPublicData & hasDBid>
  group: BaseGroupAppData
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const T: BaseModuleGroupAppData = {
//   group: {
//     id: '',
//     order: 0,
//     title: { locales: { default: '' }, _ltType: true }
//   },
//   public: {
//     type: 'Form'
//   },
//   Elements: [{ order: 3, group: 'group', id: 's' }]
// }
