import BaseModuleApp from '@/modules/baseModuleApp'
import { ModuleType } from '../typeModules'
import { RouteConfig } from 'vue-router'
import AppGroupLoading from '@/pages/app/App_Group_Loading.vue'
import { objectID } from '@/types/typeGeneral'
import { TenantID } from '@/types/typeTenant'
import {
  ServiceResponseIncidentAppData,
  ServiceResponseIncidentItemAppData,
  ServiceResponseIncidentItemRSP
} from './typeServiceModule'
import { asidID } from '@/types/typeAsid'
import { locale } from '@/types/typeI18n'

const App_Module_Service_Overview = () => import(/* webpackPrefetch: true, webpackChunkName: "App_Module_Service_Overview" */ './App_Module_Service_Overview.vue')
const App_Module_Service_Incident = () => import(/* webpackPrefetch: true, webpackChunkName: "App_Module_Service_Incident" */ './App_Module_Service_Incident.vue')

export default class ServiceModuleApp extends BaseModuleApp {
  public static type: ModuleType = 'Service'

  public static async getResponseItems(elementID: objectID, tenantID: TenantID, asidID: asidID, responseID: objectID) {
    return this.getResponseItemsHelper<ServiceResponseIncidentItemAppData>(
      elementID,
      tenantID,
      asidID,
      responseID
    )
  }

  public static async getResponses(
    elementID: objectID,
    tenantID: TenantID,
    asidID: asidID,
    responseIDs: objectID[] = [],
    responseTransactionIDs: objectID[] = []
  ) {
    return this.getResponsesHelper<ServiceResponseIncidentAppData>(
      elementID,
      tenantID,
      asidID,
      responseIDs,
      responseTransactionIDs
    )
  }

  public static addIncidentResponseItem(
    tenantID: TenantID,
    elementID: objectID,
    asidId: asidID,
    locale: locale,
    response: ServiceResponseIncidentItemRSP,
    responseID: objectID | 'new'
  ) {
    return this.addResponsesHelper(tenantID, asidId, this.type, [{ response, elementID, responseID, locale }])
  }

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'service/:groupID',
        name: 'service',
        component: App_Module_Service_Overview
      },
      {
        path: 'service/:groupID/channel/:channelID/incident/:incidentID',
        name: 'service-incident',
        component: () => ({
          component: App_Module_Service_Incident(),
          loading: AppGroupLoading
        })
      }
    ]
  }

  public static registerOverviewVue() {
    return () => ({
      component: App_Module_Service_Overview(),
      loading: AppGroupLoading
    })
  }
}
