import Vue from 'vue'

import BaseGlobals from './baseGlobals'

import { VueConstructor } from 'vue/types/umd'

import { I18nModuleDB, I18nTypeFallbackMode } from '../i18n/typeI18nModule'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
import localstorage from '@/helpers/localstorage'
import I18nModule from '../i18n/i18nModule'
import { locale, LocalizedField, LocalizedFieldObject } from '@/types/typeI18n'
import { UserPrivilegeIdDB } from '@/types/typeUser'
import { objectID } from '@/types/typeGeneral'
import { getLocalizedTextLocale, getLocalString } from '@/helpers/i18nUtil'
import notificationHelper from '@/helpers/notificationHelper'

class I18nGlobals extends BaseGlobals {
  constructor() {
    super()
  }

  init(tenantID: objectID, userPrivileges: UserPrivilegeIdDB[]): Promise<SnapshotUnbindHandle> {
    this.activeLocales = localstorage.get('i18n.activatedLocales', this.obersvableData.activeLocales, Array)

    const shared = this.obersvableData
    new Vue({
      created() {
        this.$watch(
          () => shared.activeLocales,
          (value) => {
            localstorage.set('i18n.activatedLocales', value, Array)
          }
        )
      }
    })

    if (!userPrivileges.includes('i18n:read'))
      // resturn empty dispose func
      return new Promise((resolve) =>
        resolve(() => {
          /** */
        })
      )

    // todo fill globals from outside? this global is used client and server side
    // clientsied the call below is not allowed

    return new Promise((resolve, reject) => {
      const unbindSnapshot = I18nModule.getModuleDbReference(tenantID).onSnapshot(
        (doc) => {
          // todo: or use get on client?
          if (doc.exists) {
            const data = doc.data() as I18nModuleDB
            if (data.activated) {
              this.backendEnabledLocales = ['default', ...data.public.enabledLocales]
              this.fallbackMode = data.public.fallbackLocale
              console.log('updated locals')
            }
          }
          resolve(unbindSnapshot)
        },
        (err) => {
          notificationHelper.Error(`Error while loading localisation [23230311]: ${err}`)
          reject(err)
        }
      )
    })
  }

  private obersvableData: {
    backendEnabledLocales: locale[]
    appEnabledLocales: locale[]
    appActiveLocales: locale[]
    activeLocales: locale[]
    fallbackMode: I18nTypeFallbackMode
    globalAvailableLocales: locale[]
  } = Vue.observable({
      backendEnabledLocales: ['default'],
      appEnabledLocales: ['default'],
      appActiveLocales: [],
      activeLocales: ['default'],
      fallbackMode: 'auto',
      globalAvailableLocales: []
    })

  // backendEnabledLocales selcected by the user in I18n
  public get backendEnabledLocales() {
    return this.obersvableData.backendEnabledLocales
  }

  public set backendEnabledLocales(backendEnabledLocales: locale[]) {
    Vue.set(this.obersvableData, 'backendEnabledLocales', backendEnabledLocales)
  }

  public get appEnabledLocales() {
    return this.obersvableData.appEnabledLocales
  }

  public set appEnabledLocales(appEnabledLocales: locale[]) {
    Vue.set(this.obersvableData, 'appEnabledLocales', appEnabledLocales)
  }

  public get fallbackMode() {
    return this.obersvableData.fallbackMode
  }

  public set fallbackMode(fallbackMode: I18nTypeFallbackMode) {
    this.obersvableData.fallbackMode = fallbackMode
  }

  // the first one is selected in the backend as being active
  public get activeLocales() {
    return this.obersvableData.activeLocales
  }

  // !!!! setter is not being called when setting an reactive property
  public set activeLocales(locale: locale[]) {
    console.assert(Array.isArray(locale))
    this.obersvableData.activeLocales = locale
    this.appActiveLocales = locale
      // .map((l) => l.split('-')[0]) // use only main locales de-at => de
      .filter((l) => l !== 'default') as locale[]
  }

  // the first one is selected in the backend as being active
  public get appActiveLocales() {
    return this.obersvableData.appActiveLocales
  }

  // !!!! setter is not being called when setting an reactive property
  public set appActiveLocales(appActiveLocales: locale[]) {
    this.obersvableData.appActiveLocales = appActiveLocales
  }

  /**
   * used for the language selector in the app
   * when a localized field is requested, the possible locales are added tothis list
   * the user may then choose any of the possibilities
   */
  public get globalAvailableLocales() {
    return this.obersvableData.globalAvailableLocales
  }

  public set globalAvailableLocales(locales: locale[]) {
    Vue.set(this.obersvableData, 'globalAvailableLocales', locales)
  }

  /**
   * 1. try match preferred locales 'de-de'
   * 1.1 match preferred primary lannguage 'de'
   * 2. try match default locale 'default' (mode=auto)
   * 3. try match english locale 'en' (mode=auto)
   * 4. match any locale (mode=auto)
   * @param localizedField lt object
   * @param mode defines how to macth when no macthing locale is found
   */
  public getLocalString<T extends string | number>(
    localizedField: LocalizedFieldObject<T>,
    preferredLocales: locale[] = process.env.VUE_APP_PAGE === 'app'
      ? this.appActiveLocales
      : this.activeLocales.concat(this.backendEnabledLocales), // use the backend selected locales, if it does not match, try any enabled locale
    mode: 'auto' | 'auto-hide' | locale | 'none' = this.fallbackMode || 'auto' // auto: select any locale, auto-hide: select locale, en, or default, hide otherwise
  ) {
    const availableLocales = localizedField.locales // {'de-at': 'titel'}

    // remove empty keys
    Object.keys(availableLocales).forEach((key) => String(availableLocales[key]).trim() === '' && delete availableLocales[key])

    // convert specific locales "fr-be" to primary locale "fr"
    const availablePrimaryLocales = Object.keys(availableLocales).map((key) => key.split('-')[0]) as locale[]

    // add requested locale to the globalAvailableLocales which is the basis for the apps language selection
    for (const locale of availablePrimaryLocales) {
      if (!this.globalAvailableLocales.includes(locale) && locale !== 'default')
        this.globalAvailableLocales.push(locale)
    }

    return getLocalString(localizedField, preferredLocales, mode)
  }

  public getLocalizedTextLocale(
    localizedField: LocalizedField,
    preferredLocales: locale[] = process.env.VUE_APP_PAGE === 'app'
      ? this.appActiveLocales
      : this.activeLocales.concat(this.backendEnabledLocales), // use the backend selected locales, if it does not match, try any enabled locale
    mode: 'auto' | 'auto-hide' | locale | 'none' = 'auto' // auto: select any locale, auto-hide: select locale, en, or default, hide otherwise
  ) {
    return getLocalizedTextLocale(localizedField, preferredLocales, mode)
  }
}

export const I18nGlobalsInst = new I18nGlobals()

export type typeI18nGlobals = I18nGlobals

declare module 'vue/types/vue' {
  interface Vue {
    $i18n: I18nGlobals
  }
}

export default {
  install(Vue: VueConstructor, options: any) {
    Vue.prototype.$i18n = I18nGlobalsInst
  }
}
