// make listeners global for simpler debugging
let listeners: {
  eventName: string
  cb: (eventData: any) => void
  listenerToken: number
  subscriptionGroupID: string
  subscriptionID: string
}[] = []

let listenerTokenCounter = 0

export default class Event<EventType> {
  private debugName = ''
  private eventName = ''
  private pastEventsBuffer: EventType[] = []
  private MAX_PAST_EVENTS = 10

  constructor(eventName: string, debugName: string) {
    this.eventName = eventName
    this.debugName = debugName
  }

  /**
   * listen to a specific event topic
   * call returned function to unsubscribe
   * @param cb callback function
   * @param subscriptionGroupID optional group id to unsubscribe multiple listeners at once
   * @param invokeWithPastEvents if true, past events are called immediately
   * @param subscriptionID optional id if used the subscription with the same id will be unsubscribed first. It can not be unsubscribed with unsubscribeAll
   */
  public on({
    cb,
    subscriptionGroupID = '',
    invokeWithPastEvents = false,
    subscriptionID = ''
  }: {
    cb: (eventData: EventType) => void
    subscriptionGroupID?: string
    invokeWithPastEvents?: boolean
    subscriptionID?: string
  }) {
    const listenerToken = listenerTokenCounter++

    // unsubscribe if subscriptionID is given
    if (subscriptionID) {
      listeners = listeners.filter((listener) => listener.subscriptionID !== subscriptionID)
    }

    console.debug(
      `EventBus [${this.debugName}]: on '${this.eventName}' registered. Currently ${listeners.length} total listeners and ${listeners.filter(
        (listener) => listener.eventName === this.eventName
      ).length} listeners for this event`
    )

    listeners.push({
      listenerToken,
      eventName: this.eventName,
      subscriptionGroupID,
      subscriptionID,
      cb: (data) => {
        if (this.debugName) console.debug(`EventBus [${this.debugName}]: on '${this.eventName} called'`)
        cb(data)
      }
    })

    if (invokeWithPastEvents) {
      console.debug(`EventBus [${this.debugName}]: on '${this.eventName} called with past events'`)

      if (this.pastEventsBuffer.length > this.MAX_PAST_EVENTS) {
        console.warn(`EventBus [${this.debugName}]: on '${this.eventName} past events buffer overflow'`)
      }

      this.pastEventsBuffer.forEach((event) => {
        cb(event)
      })
    }

    return () => {
      if (this.debugName) console.debug(`EventBus [${this.debugName}]: on '${this.eventName} unbound'`)

      listeners = listeners.filter((listener) => listener.listenerToken !== listenerToken)
    }
  }

  /**
   * emit data to a specific event topic
   */
  public emit(eventData: EventType) {
    if (this.debugName) console.debug(`EventBus [${this.debugName}]: emit '${this.eventName} emitted'`)

    this.pastEventsBuffer.push(eventData)
    if (this.pastEventsBuffer.length > this.MAX_PAST_EVENTS) {
      this.pastEventsBuffer.shift()
    }

    listeners.forEach((listener) => {
      if (listener.eventName === this.eventName) {
        listener.cb(eventData)
      }
    })
  }

  /**
   * unsubscribe from all listeners from this topic/eventName
   * @param subscriptionGroupID optional group id to unsubscribe multiple listeners at once. if not given, all listeners without subscriptionID will be unsubscribed
   */
  public unsubscribeAll(subscriptionGroupID?: string) {
    if (this.debugName) console.debug(`EventBus [${this.debugName}]: unsubscribeAll`)
    if (subscriptionGroupID) console.debug(`EventBus [${this.debugName}]: unsubscribeAll for group ${subscriptionGroupID}`)

    listeners = listeners.filter((listener) => {
      const sameEventName = listener.eventName === this.eventName
      const sameSubscriptionGroup = listener.subscriptionGroupID === subscriptionGroupID

      return !sameEventName || (subscriptionGroupID && !sameSubscriptionGroup) || listener.subscriptionID
    })

    // clear past events buffer
    this.pastEventsBuffer = []
  }
}
