type dataType = ObjectConstructor | StringConstructor | NumberConstructor | BooleanConstructor | ArrayConstructor

class LocalStorage {
  private _namespace = ''
  private _isSupported = true
  /**
   * VueLocalStorage constructor
   */
  constructor() {
    //
  }

  /**
   * Namespace getter.
   */
  get namespace() {
    return this._namespace
  }

  /**
   * Namespace setter.
   */
  set namespace(value) {
    this._namespace = value ? `${value}.` : ''
  }

  /**
   * Concatenates localStorage key with namespace prefix.
   */
  private getLsKey(lsKey: string) {
    return `${this._namespace}${lsKey}`
  }

  /**
   * Set a value to localStorage giving respect to the namespace.
   */
  private lsSet(lsKey: string, rawValue: any, type?: dataType) {
    const value
      = (!type && (typeof rawValue == 'object' || rawValue instanceof Array))
      || (type && (type === Array || type === Object))
        ? JSON.stringify(rawValue)
        : rawValue

    window.localStorage.setItem(this.getLsKey(lsKey), value)
  }

  /**
   * Get value from localStorage giving respect to the namespace.
   */
  private lsGet(key: string, type: dataType) {
    const lsKey = this.getLsKey(key)

    const value = window.localStorage[lsKey]

    if (value !== undefined) {
      switch (type) {
        case Boolean:
          return value === 'true'
        case Number:
          return parseFloat(value)
        case Array:
          try {
            const array = JSON.parse(value)

            return Array.isArray(array) ? array : []
          } catch (e: any) {
            return []
          }
        case Object:
          try {
            return JSON.parse(value)
          } catch (e: any) {
            return {}
          }
        default:
          return value
      }
    }
    return undefined
  }

  /**
   * Get value from localStorage
   */
  public get<T>(lsKey: string, defaultValue: T, defaultType: dataType): T {
    if (!this._isSupported) {
      return defaultValue
    }

    return this.lsGet(lsKey, defaultType) === undefined ? defaultValue : this.lsGet(lsKey, defaultType)
  }

  /**
   * Set localStorage value
   */
  public set(lsKey: string, value: any, type?: dataType) {
    this.lsSet(lsKey, value, type)

    return value
  }

  /**
   * Remove value from localStorage
   */
  public remove(key: string) {
    if (!this._isSupported) {
      return null
    }

    return window.localStorage.removeItem(this.getLsKey(key))
  }
}

export default new LocalStorage()
