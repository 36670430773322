<template>
  <div class="app-main-wrapper">
    <b-notification
      v-if="env !== 'emulator'"
      :active.sync="updateNotificationActive"
      :type="`is-warning`"
      aria-close-label="Close notification"
      class="new-version-notification"
    >
      A new build version of this application is available (local: {{ (+localBuildTime).toString(36) }}, remote: {{ (+remoteBuildTime).toString(36) }}). Reload the page to use it. If this banner stays after a reload, try to clear the browser cache.
      <b-button @click="onReloadPage">Reload</b-button>
    </b-notification>
    <!-- todo remove id here? also in index.html -->
    <router-view />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { handlePromiseError } from './helpers/notificationHelper'


@Component({
  components: {}
})
export default class App extends Vue {
  public updateNotificationActive = false
  // public localVersion = process.env.VUE_APP_VERSION
  // use this instead of process, since this file is cached and does not update on realod, thus the version is still the old one => was an artefact of index.html being cached. works now
  // public localVersion = (window as any)?.VAR?.VUE_APP_VERSION || ''
  public localBuildTime = process.env.VUE_APP_BUILD_TIME || '' // use this instead of process, since this file is cached and does not updtae on realod, thus the version is still the old one
  public remoteBuildTime = process.env.VUE_APP_BUILD_TIME || '' // use this instead of process, since this file is cached and does not updtae on realod, thus the version is still the old one

  private VERSION_CHECK_EVERY_SECONDS = 10 * 60

  public env = process.env.VUE_APP_FIREBASE_ENV

  public onReloadPage() {
    window.location.reload()
  }

  public async mounted() {
    console.log('app version ', this.localBuildTime)
    const checkVersionUpdate = async () => {
      try {
        const buildInfo = (
          await fetch('/public/version.json', { cache: 'no-store', credentials: 'same-origin' })
            .then((response) => response.json())
        ) as {
          name: string
          buildTime: string
          version: string
        }
        console.log(buildInfo)
        this.remoteBuildTime = buildInfo.buildTime
        this.updateNotificationActive = (this.remoteBuildTime != this.localBuildTime)
      } catch (error) {
        console.error('fetch build info failed', error)
      }
    }
    setInterval(() => handlePromiseError(checkVersionUpdate()), 1000 * this.VERSION_CHECK_EVERY_SECONDS)

    await checkVersionUpdate()
  }
}
</script>

<style>
article.notification.new-version-notification {
  margin-bottom: 0;
}

.notification.is-danger.browser-support {
  margin-bottom: 0;
}

.loading-overlay.is-active {
  /* z-index: 999; */
}

.app-main-wrapper {
  height: 100vh;
}
</style>
