export const LOCALES_NAME_MAP = {
  'default': 'Default',
  'af': 'Afrikaans',
  'sq': 'Albanian',
  'ar': 'Arabic (Standard)',
  'ar-dz': 'Arabic (Algeria)',
  'ar-bh': 'Arabic (Bahrain)',
  'ar-eg': 'Arabic (Egypt)',
  'ar-iq': 'Arabic (Iraq)',
  'ar-jo': 'Arabic (Jordan)',
  'ar-kw': 'Arabic (Kuwait)',
  'ar-lb': 'Arabic (Lebanon)',
  'ar-ly': 'Arabic (Libya)',
  'ar-ma': 'Arabic (Morocco)',
  'ar-om': 'Arabic (Oman)',
  'ar-qa': 'Arabic (Qatar)',
  'ar-sa': 'Arabic (Saudi Arabia)',
  'ar-sy': 'Arabic (Syria)',
  'ar-tn': 'Arabic (Tunisia)',
  'ar-ae': 'Arabic (U.A.E.)',
  'ar-ye': 'Arabic (Yemen)',
  'an': 'Aragonese',
  'hy': 'Armenian',
  'as': 'Assamese',
  'ast': 'Asturian',
  'az': 'Azerbaijani',
  'eu': 'Basque',
  'bg': 'Bulgarian',
  'be': 'Belarusian',
  'bn': 'Bengali',
  'bs': 'Bosnian',
  'br': 'Breton',
  'my': 'Burmese',
  'ca': 'Catalan',
  'ch': 'Chamorro',
  'ce': 'Chechen',
  'zh': 'Chinese',
  'zh-hk': 'Chinese (Hong Kong)',
  'zh-cn': 'Chinese (PRC)',
  'zh-sg': 'Chinese (Singapore)',
  'zh-tw': 'Chinese (Taiwan)',
  'cv': 'Chuvash',
  'co': 'Corsican',
  'cr': 'Cree',
  'hr': 'Croatian',
  'cs': 'Czech',
  'da': 'Danish',
  'nl': 'Dutch (Standard)',
  'nl-be': 'Dutch (Belgian)',
  'en': 'English',
  'en-au': 'English (Australia)',
  'en-bz': 'English (Belize)',
  'en-ca': 'English (Canada)',
  'en-ie': 'English (Ireland)',
  'en-jm': 'English (Jamaica)',
  'en-nz': 'English (New Zealand)',
  'en-ph': 'English (Philippines)',
  'en-za': 'English (South Africa)',
  'en-tt': 'English (Trinidad & Tobago)',
  'en-gb': 'English (United Kingdom)',
  'en-us': 'English (United States)',
  'en-zw': 'English (Zimbabwe)',
  'eo': 'Esperanto',
  'et': 'Estonian',
  'fo': 'Faeroese',
  'fa': 'Farsi/Persian',
  'fj': 'Fijian',
  'fi': 'Finnish',
  'fr': 'French (Standard)',
  'fr-be': 'French (Belgium)',
  'fr-ca': 'French (Canada)',
  'fr-fr': 'French (France)',
  'fr-lu': 'French (Luxembourg)',
  'fr-mc': 'French (Monaco)',
  'fr-ch': 'French (Switzerland)',
  'fy': 'Frisian',
  'fur': 'Friulian',
  'gd': 'Gaelic (Scots)',
  'gd-ie': 'Gaelic (Irish)',
  'gl': 'Galacian',
  'ka': 'Georgian',
  'de': 'German',
  'de-at': 'German (Austria)',
  'de-de': 'German (Germany)',
  'de-li': 'German (Liechtenstein)',
  'de-lu': 'German (Luxembourg)',
  'de-ch': 'German (Switzerland)',
  'el': 'Greek',
  'gu': 'Gujurati',
  'ht': 'Haitian',
  'he': 'Hebrew',
  'hi': 'Hindi',
  'hu': 'Hungarian',
  'is': 'Icelandic',
  'id': 'Indonesian',
  'iu': 'Inuktitut',
  'ga': 'Irish',
  'it': 'Italian (Standard)',
  'it-ch': 'Italian (Switzerland)',
  'ja': 'Japanese',
  'kn': 'Kannada',
  'ks': 'Kashmiri',
  'kk': 'Kazakh',
  'km': 'Khmer',
  'ky': 'Kirghiz',
  'tlh': 'Klingon',
  'ko': 'Korean',
  'ko-kp': 'Korean (North Korea)',
  'ko-kr': 'Korean (South Korea)',
  'la': 'Latin',
  'lv': 'Latvian',
  'lt': 'Lithuanian',
  'lb': 'Luxembourgish',
  'mk': 'FYRO Macedonian',
  'ms': 'Malay',
  'ml': 'Malayalam',
  'mt': 'Maltese',
  'mi': 'Maori',
  'mr': 'Marathi',
  'mo': 'Moldavian',
  'nv': 'Navajo',
  'ng': 'Ndonga',
  'ne': 'Nepali',
  'no': 'Norwegian',
  'nb': 'Norwegian (Bokmal)',
  'nn': 'Norwegian (Nynorsk)',
  'oc': 'Occitan',
  'or': 'Oriya',
  'om': 'Oromo',
  'fa-ir': 'Persian/Iran',
  'pl': 'Polish',
  'pt': 'Portuguese',
  'pt-br': 'Portuguese (Brazil)',
  'pa': 'Punjabi',
  'pa-in': 'Punjabi (India)',
  'pa-pk': 'Punjabi (Pakistan)',
  'qu': 'Quechua',
  'rm': 'Rhaeto-Romanic',
  'ro': 'Romanian',
  'ro-mo': 'Romanian (Moldavia)',
  'ru': 'Russian',
  'ru-mo': 'Russian (Moldavia)',
  'sz': 'Sami (Lappish)',
  'sg': 'Sango',
  'sa': 'Sanskrit',
  'sc': 'Sardinian',
  'sd': 'Sindhi',
  'si': 'Singhalese',
  'sr': 'Serbian',
  'sk': 'Slovak',
  'sl': 'Slovenian',
  'so': 'Somani',
  'sb': 'Sorbian',
  'es': 'Spanish',
  'es-ar': 'Spanish (Argentina)',
  'es-bo': 'Spanish (Bolivia)',
  'es-cl': 'Spanish (Chile)',
  'es-co': 'Spanish (Colombia)',
  'es-cr': 'Spanish (Costa Rica)',
  'es-do': 'Spanish (Dominican Republic)',
  'es-ec': 'Spanish (Ecuador)',
  'es-sv': 'Spanish (El Salvador)',
  'es-gt': 'Spanish (Guatemala)',
  'es-hn': 'Spanish (Honduras)',
  'es-mx': 'Spanish (Mexico)',
  'es-ni': 'Spanish (Nicaragua)',
  'es-pa': 'Spanish (Panama)',
  'es-py': 'Spanish (Paraguay)',
  'es-pe': 'Spanish (Peru)',
  'es-pr': 'Spanish (Puerto Rico)',
  'es-es': 'Spanish (Spain)',
  'es-uy': 'Spanish (Uruguay)',
  'es-ve': 'Spanish (Venezuela)',
  'sx': 'Sutu',
  'sw': 'Swahili',
  'sv': 'Swedish',
  'sv-fi': 'Swedish (Finland)',
  'sv-sv': 'Swedish (Sweden)',
  'ta': 'Tamil',
  'tt': 'Tatar',
  'te': 'Teluga',
  'th': 'Thai',
  'tig': 'Tigre',
  'ts': 'Tsonga',
  'tn': 'Tswana',
  'tr': 'Turkish',
  'tk': 'Turkmen',
  'uk': 'Ukrainian',
  'hsb': 'Upper Sorbian',
  'ur': 'Urdu',
  've': 'Venda',
  'vi': 'Vietnamese',
  'vo': 'Volapuk',
  'wa': 'Walloon',
  'cy': 'Welsh',
  'xh': 'Xhosa',
  'ji': 'Yiddish',
  'zu': 'Zulu'
}

export type locale = keyof typeof LOCALES_NAME_MAP

// export type LocalizedField = string
export type LocalizedField = {
  _ltType: boolean // used to dynamically identify localized text at runtime
  _hidden?: boolean // hidden in i18n module for translation
  locales: LocalizedFieldLocales
}

export type LocalizedFieldLocales = LocalizedFieldObjectLocales<string>

export type LocalizedFieldObject<T> = {
  _ltType: boolean // used to dynamically identify localized text at runtime
  _hidden?: boolean // hidden in i18n module for translation
  locales: LocalizedFieldObjectLocales<T>
}


export type LocalizedFieldObjectLocales<T> = {
  [locale: string]: T
}

// export type LocalizedFieldLocales = {
//   default?: string
//   af?: string
//   sq?: string
//   ar?: string
//   'ar-dz'?: string
//   'ar-bh'?: string
//   'ar-eg'?: string
//   'ar-iq'?: string
//   'ar-jo'?: string
//   'ar-kw'?: string
//   'ar-lb'?: string
//   'ar-ly'?: string
//   'ar-ma'?: string
//   'ar-om'?: string
//   'ar-qa'?: string
//   'ar-sa'?: string
//   'ar-sy'?: string
//   'ar-tn'?: string
//   'ar-ae'?: string
//   'ar-ye'?: string
//   an?: string
//   hy?: string
//   as?: string
//   ast?: string
//   az?: string
//   eu?: string
//   bg?: string
//   be?: string
//   bn?: string
//   bs?: string
//   br?: string
//   my?: string
//   ca?: string
//   ch?: string
//   ce?: string
//   zh?: string
//   'zh-hk'?: string
//   'zh-cn'?: string
//   'zh-sg'?: string
//   'zh-tw'?: string
//   cv?: string
//   co?: string
//   cr?: string
//   hr?: string
//   cs?: string
//   da?: string
//   nl?: string
//   'nl-be'?: string
//   en?: string
//   'en-au'?: string
//   'en-bz'?: string
//   'en-ca'?: string
//   'en-ie'?: string
//   'en-jm'?: string
//   'en-nz'?: string
//   'en-ph'?: string
//   'en-za'?: string
//   'en-tt'?: string
//   'en-gb'?: string
//   'en-us'?: string
//   'en-zw'?: string
//   eo?: string
//   et?: string
//   fo?: string
//   fa?: string
//   fj?: string
//   fi?: string
//   fr?: string
//   'fr-be'?: string
//   'fr-ca'?: string
//   'fr-fr'?: string
//   'fr-lu'?: string
//   'fr-mc'?: string
//   'fr-ch'?: string
//   fy?: string
//   fur?: string
//   gd?: string
//   'gd-ie'?: string
//   gl?: string
//   ka?: string
//   de?: string
//   'de-at'?: string
//   'de-de'?: string
//   'de-li'?: string
//   'de-lu'?: string
//   'de-ch'?: string
//   el?: string
//   gu?: string
//   ht?: string
//   he?: string
//   hi?: string
//   hu?: string
//   is?: string
//   id?: string
//   iu?: string
//   ga?: string
//   it?: string
//   'it-ch'?: string
//   ja?: string
//   kn?: string
//   ks?: string
//   kk?: string
//   km?: string
//   ky?: string
//   tlh?: string
//   ko?: string
//   'ko-kp'?: string
//   'ko-kr'?: string
//   la?: string
//   lv?: string
//   lt?: string
//   lb?: string
//   mk?: string
//   ms?: string
//   ml?: string
//   mt?: string
//   mi?: string
//   mr?: string
//   mo?: string
//   nv?: string
//   ng?: string
//   ne?: string
//   no?: string
//   nb?: string
//   nn?: string
//   oc?: string
//   or?: string
//   om?: string
//   'fa-ir'?: string
//   pl?: string
//   pt?: string
//   'pt-br'?: string
//   pa?: string
//   'pa-in'?: string
//   'pa-pk'?: string
//   qu?: string
//   rm?: string
//   ro?: string
//   'ro-mo'?: string
//   ru?: string
//   'ru-mo'?: string
//   sz?: string
//   sg?: string
//   sa?: string
//   sc?: string
//   sd?: string
//   si?: string
//   sr?: string
//   sk?: string
//   sl?: string
//   so?: string
//   sb?: string
//   es?: string
//   'es-ar'?: string
//   'es-bo'?: string
//   'es-cl'?: string
//   'es-co'?: string
//   'es-cr'?: string
//   'es-do'?: string
//   'es-ec'?: string
//   'es-sv'?: string
//   'es-gt'?: string
//   'es-hn'?: string
//   'es-mx'?: string
//   'es-ni'?: string
//   'es-pa'?: string
//   'es-py'?: string
//   'es-pe'?: string
//   'es-pr'?: string
//   'es-es'?: string
//   'es-uy'?: string
//   'es-ve'?: string
//   sx?: string
//   sw?: string
//   sv?: string
//   'sv-fi'?: string
//   'sv-sv'?: string
//   ta?: string
//   tt?: string
//   te?: string
//   th?: string
//   tig?: string
//   ts?: string
//   tn?: string
//   tr?: string
//   tk?: string
//   uk?: string
//   hsb?: string
//   ur?: string
//   ve?: string
//   vi?: string
//   vo?: string
//   wa?: string
//   cy?: string
//   xh?: string
//   ji?: string
//   zu?: string
// }
