/**
 * The App is used standalone and also embedded into the backend to preview widgets (groups) and elements
 * In the backend it can not use the default vue router, since it relies on changes of the url
 * Therefore the app uses an abstraction for navigation by listening to chnages of the state router and displaying the respective components
 * In standalone mode it is still linked to the url using some vue-router glue logic
 */

import Event from '@/helpers/eventBus'

const ENABLE_LOG = true

const eventBus = new Event<string>('app-path-change', 'appStateRouter')

let appPath = ''

/**
 *
 * @param callback
 * @param initial if true, callback gets called once with the current value
 */
export const onAppPathChange = (callback: (path: string) => void, initial = true) => {
  if (initial) callback(appPath)
  return eventBus.on({
    cb: (path: string) => {
      if (ENABLE_LOG) console.log(`onAppPathChange new: "${path}"`)
      callback(path)
    }
  })
}

export const setAppPath = (path: string) => {
  if (ENABLE_LOG) console.log(`setAppPath old: "${appPath}" > new: "${path}"`)
  if (appPath === path) return // avoid redundant navigation
  // if it starts with a slash, remove it
  if (path.startsWith('/')) path = path.substring(1)
  appPath = path
  eventBus.emit(path)
}

export const getAppPath = () => {
  if (ENABLE_LOG) console.log(`getAppPath: "${appPath}"`)
  return appPath
}

export let urlPathPrefix = '' // for generating hrefs in the app, the correct path shall be used
export const setUrlPathPrefix = (prefix: string) => {
  urlPathPrefix = prefix
}
