<template>
  <section ref="app-main" class="app-main">
    <v-app-router-link
      class="hero is-primary header"
      style="background-color: var(--header-color-background);"
      :to="{ name: 'asid' }"
      @click.native="handleClickForPreview({ type: 'header' })"
    >
      <div id="header-logo-container-padding" class="header-logo-container-padding">
        <div id="header-logo-container-alignment">
          <h1 id="header-logo" class="title is-2" style="color: var(--header-color-text);">
            <span class="default-logo">{{ isLoading ? 'Loading' : 'ECHO PRM' }}</span>
          </h1>
        </div>
      </div>
    </v-app-router-link>
    <section class="app-body">
      <b-message
        v-if="errorText"
        type="is-danger"
        has-icon
        aria-close-label="Close message"
        icon="exclamation-circle"
        icon-pack="fa"
      >
        <span style="white-space: pre;">{{ errorText }}</span>
      </b-message>
      <!-- <b-button v-for="(data,type) in moduleAppData" :key="type" size="is-large" style>{{ type }}</b-button> -->
      <div ref="widget-container" class="widget-container">
        <div
          v-if="moduleAppDatasForRoute.type === 'page' || moduleAppDatasForRoute.type === 'asid'"
          class="widget-container-overview"
        >
          <!-- {{ debug()}} -->
          <component
            :is="moduleGroupsOverview.component"
            v-for="moduleGroupsOverview in moduleAppDatasForRoute.tuples"
            :id="moduleGroupsOverview.type.toLowerCase() + '-' + moduleGroupsOverview.moduleAppData.group.id"
            :key="moduleGroupsOverview.type + '_overview_' + moduleGroupsOverview.moduleAppData.group.id"
            :module-app-data="moduleGroupsOverview.moduleAppData"
            :global-module-app-datas="appData"
            :asid="asid"
            :tenant-id="appData.globalData.tenantID"
            is-overview
            class="widget-overview"
            :class="[
              `module-type-${moduleGroupsOverview.type.toLowerCase()}`,
              `widget-style-${moduleGroupsOverview.moduleAppData.group.display.displayType}`,
              `widget-position-${moduleGroupsOverview.localData && moduleGroupsOverview.localData.position}`
            ]"
            @keyProvided="onKeyProvided"
          />
        </div>
        <div v-if="moduleAppDatasForRoute.type !== 'page'" class="widget-container-single">
          <component
            :is="moduleAppDatasForRoute.tuples[0].component"
            :id="moduleAppDatasForRoute.tuples[0].type === 'Protection' ? 'protection-fullpage' : ''"
            :module-app-data="moduleAppDatasForRoute.tuples[0].moduleAppData"
            :global-module-app-datas="appData"
            :asid="asid"
            :tenant-id="appData.globalData.tenantID"
            :is-overview="false"
            v-bind="moduleAppDatasForRoute.tuples[0].props"
            :class="[
              'widget-single',
              `module-type-${(moduleAppDatasForRoute.tuples[0].type || '').toLowerCase()}`,
              'widget-style-inline'
            ]"
            @keyProvided="onKeyProvided"
          />
          <!-- <router-view
              v-else
              :module-app-data="getModuleAppDataForRoute().moduleAppData"
              :global-module-app-datas="aeAppData"
              :is-overview="false"
              :asid="asid"
              :tenant-id="appData.globalData.tenantID"
              :class="['widget-single',`module-type-${($route.meta.moduleType || '').toLowerCase()}`]"
              @keyProvided="onKeyProvided"
          />-->
        </div>
        <component
          :is="moduleBackground.component"
          v-for="moduleBackground in moduleGroupsBackgroundVue"
          v-show="false"
          :key="moduleBackground.type + '_background'"
          :tenant-id="appData.globalData.tenantID"
          :module-app-data="moduleBackground.moduleAppData"
          :global-module-app-datas="appData"
          :asid="asid"
        />
      </div>

      <!-- <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" /> -->
    </section>
    <footer
      class="footer"
      @click="handleClickForPreview({ type: 'footer' })"
      @mousedown="onTouchStartFooter"
      @touchstart="onTouchStartFooter"
      @touchend="onTouchEndFooter"
      @mouseup="onTouchEndFooter"
      @mouseleave="onTouchEndFooter"
    >
      <div id="footer-logo-container-padding">
        <div id="footer-logo-container-alignment">
          <h1
            id="footer-logo"
            class="title is-2"
            style="color: var(--footer-color-text);"
          >{{ isLoading ? 'Loading' : '' }}</h1>
          <!-- <img v-if="customStyle.logo" :src="customStyle.logo" alt="logo" /> -->
        </div>
      </div>
      <div class="app-content has-text-centered">
        <p v-if="globalAvailableLocales.length > 0" class="language-select">
          <b-button icon-right="language" type="is-text" @click.prevent="onResetAppActiveLocales" />
          <a
            v-for="locale in globalAvailableLocales"
            :key="locale"
            :class="{ active: $i18n.appActiveLocales[0] === locale || $i18n.appActiveLocales[0]?.split('-')?.[0] === locale }"
            class="button is-text"
            @click.prevent="setActiveLocales([locale])"
          >{{ getLocaleName(locale) }}</a>
        </p>
        <!-- <p>
          An <strong>ECHO</strong> PRM GmbH Solution -
          Connecting Users and Producers
        </p>-->
        <p>
          <span v-if="!isLoading" class="legal">
            <span v-for="type in legalKeys" :key="type">
              <v-app-router-link
                v-if="$i18n.getLocalString(appData.globalData.legal[type].linkTitle, $i18n.appActiveLocales) !== ''"
                class="legal-links"
                :to="{ name: 'legal', params: { type } }"
              >{{ $i18n.getLocalString(appData.globalData.legal[type].linkTitle, $i18n.appActiveLocales) }}</v-app-router-link>
            </span>
          </span>
        </p>
      </div>
    </footer>
  </section>
</template>

<script lang="ts">
import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowUp, faLanguage, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import ModuleManagerApp, { ModuleAppDataVueComponentTuple } from '@/modules/moduleManagerApp'

import { locale } from '@/types/typeI18n'

import VAppRouterLink from '@/pages/app/components/VAppRouterLink.vue'
import CustomScriptExecution from '@/modules/custom/customModuleUtils'
import { onAppPathChange, setAppPath } from '@/pages/app/appStateRouter'
import { asidID } from '@/types/typeAsid'
import { ModuleType } from '@/modules/typeModules'
import { objectID } from '@/types/typeGeneral'
import { intersect } from '@/helpers/arrayHelper'
import { install } from 'resize-observer'
import { copyDebugInformationToClipboard } from '@/helpers/debugInfoHelper'
import { RPCAppDataResponse } from '@/types/typeRPC'
import { setSingletClickEventListener } from '@/helpers/domHelper'
import Event from '@/helpers/eventBus'

library.add(faArrowUp, faLanguage, faExclamationCircle)

export interface AppClickedEvent {
  type: 'header' | 'footer' | 'element' | 'group'
  moduleType?: ModuleType
  docID?: objectID
}

const PreviewEventBus = new Event<AppClickedEvent>('click-app-preview', 'vapp')

export const clickAppPreview = (appClickEvent: AppClickedEvent) => {
  PreviewEventBus.emit(appClickEvent)
}

export let urlPathPrefix = '' // for generating hrefs in the app, the correct path shall be used
export const setUrlPathPrefix = (prefix: string) => {
  urlPathPrefix = prefix
}

@Component({
  components: { VAppRouterLink }
})
export default class VApp extends Vue {
  /**
   * { moduleGroupAppDatas: [], globalData: { tenantID: '', sessionID: '', dataHash: '', appData.globalData.legal: defaultLegalConfig } }
   */
  @Prop({ type: Object, required: true })
  public readonly appData!: RPCAppDataResponse

  @Prop({ type: String, required: true })
  public readonly asid!: asidID

  @Prop({ type: Boolean, required: false, default: false })
  public liveUpdatePreviewMode!: boolean

  public initialLoad = true

  @Prop({ type: String, required: false, default: '' })
  public externalErrorText!: string

  public get legalKeys(): Array<keyof typeof this.appData.globalData.legal> {
    return ['imprint', 'terms', 'privacy', 'other']
  }

  public getLocaleName(locale: locale) {
    // return getLocaleName(locale, false)
    return locale
  }

  public setActiveLocales(locale: locale[]) {
    this.$i18n.appActiveLocales = locale
    this.$i18n.activeLocales = locale
  }

  // #region debug info handling

  /**
   * copy debug info to clipboard if the footer is pressed for longer than 5 seconds
   */

  private timer?: any
  private isPressedForFiveSeconds = false
  private TIME_PRESS_TITLE_TO_COPY_DEBUG = 1000 * 5 // 5s

  public onTouchStartFooter() {
    console.log('start')

    this.timer = setTimeout(() => {
      this.isPressedForFiveSeconds = true
      this.$helpers.notification.Info('Release your finger to copy debug info.')
    }, this.TIME_PRESS_TITLE_TO_COPY_DEBUG)
  }

  public debug() {
    //  debugger
  }

  public onTouchEndFooter() {
    console.log('end')

    if (this.isPressedForFiveSeconds) {
      const successfull = copyDebugInformationToClipboard({
        asid: this.asid,
        sessionID: this.appData.globalData.sessionID,
        tenantID: this.appData.globalData.tenantID
      })

      if (successfull) {
        this.$helpers.notification.Info('Debug information copied to clipboard')
      } else {
        this.$helpers.notification.Error('Error occured while trying to copy debug information to clipboard')
      }
    }

    this.isPressedForFiveSeconds = false
    clearTimeout(this.timer)
  }

  // #endregion debug info handling

  // #region path handling

  @PropSync('path', { type: String, required: false, default: '' })
  public pathSync!: string

  @Watch('pathSync')
  private onRouteChanged() {
    CustomScriptExecution.eventPageChange.emit(this.pathSync)
  }

  @Watch('pathSync', { immediate: true })
  private onPathChanged() {
    const p = this.pathSync
    // remove trailing slash "/"" from path
    const path = p.replace(/\/$/, '')
    setAppPath(path)
  }

  private pathHandlingUnbindHandler?: () => void

  private initPathHandling() {
    // disable browser scroll state restoration
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual'
    }

    if (this.pathHandlingUnbindHandler) this.pathHandlingUnbindHandler()

    this.pathHandlingUnbindHandler = onAppPathChange((path) => {
      this.pathSync = path
    })
  }

  // #endregion path handling

  get errorText() {
    return [this.localErrorText, this.externalErrorText, this.localRouteErrorText]
      .filter((t) => t) // filter out empty strings
      .join('\n')
  }

  // todo may be used to identify if user was already on this page
  public appLifecycle = { initialAsidVisit: true, initialAppVisit: true }

  public isLoading = true

  public localErrorText = ''
  public localRouteErrorText = ''

  public moduleGroupsBackgroundVue: ModuleAppDataVueComponentTuple[] = []

  public moduleAppDatasForRoute = {
    type: 'error',
    tuples: [] as (ModuleAppDataVueComponentTuple & { localData?: { position: 'left' | 'right' } })[]
  }

  @Watch('appData', { immediate: true, deep: true })
  @Watch('pathSync', { immediate: false, deep: false })
  async onUpdateRoute() {
    // update the tuples for the new route only after all promises have been loaded to prevent flickering  =>
    // and to allow for automatic scroll position restoration => does not work reliable, doing it manually now
    try {
      const tuples = ModuleManagerApp.getRouteTuples(this.appData, this.pathSync)

      if (tuples.tuples.length > 0) this.localRouteErrorText = ''

      this.moduleAppDatasForRoute = tuples
    } catch (error) {
      this.$helpers.notification.Error(error)
      this.localRouteErrorText += `Error 404 \npage "${this.pathSync}" not found`

      this.moduleAppDatasForRoute = {
        type: 'error',
        tuples: []
      }
    }
  }

  /**
   * scroll position handling
   * [{
   *   path: scrollPosition,
   *   '/page2': 150,
   * }]
   */
  private scrollPositionMap: { [key: string]: number } = {}

  @Watch('pathSync', { immediate: true, deep: false })
  private async handleScrollPosition(newPath: string, oldPath: string) {
    /**
     * when leaving a page, the scroll position shall be saved
     * when returning to the page, the scroll position shall be restored
     */

    // convert empty string to _main_ to have a unique key
    if (oldPath === '') oldPath = '_main_'
    if (newPath === '') newPath = '_main_'

    // should not happen, but just in case
    if (oldPath === newPath) return

    // save the scroll position when leaving the page
    // if the newPath is a link, dont save the scroll position as its just from the link redirection page
    if (oldPath !== undefined) {
      const $appEl = this.$refs['app-main'] as HTMLElement

      if (!$appEl) {
        console.error('app-main element not found')
        return
      }

      const $container = $appEl?.parentElement?.parentElement?.parentElement?.parentElement
      if (!$container) {
        console.error('container element not found')
        return
      }

      const scrollTop = $container.scrollTop || 0
      this.scrollPositionMap[oldPath] = scrollTop
    }

    // restore the scroll position when returning to the page
    const scrollPosition = this.scrollPositionMap[newPath]

    if (scrollPosition) {
      // wait for render. dirty trick as there seem to be no way to reliable detect complete rendering also including images
      await this.$nextTick()
      await this.$nextTick()
      await this.$nextTick()
      await this.$nextTick()
      console.debug('scrolling to', scrollPosition)
      window.scrollTo(0, scrollPosition)
    }
  }

  get globalAvailableLocales(): locale[] {
    const locales = this.$i18n.appEnabledLocales
      .map((l) => l.split('-')[0]) // use only main locales de-at => de
      .filter((l) => l !== 'default')

    return intersect(this.$i18n.globalAvailableLocales, [...new Set(locales)]) as locale[]
  }

  @Emit('keyProvided')
  public onKeyProvided(key: string) {
    return key
  }

  public created() {
    this.initPathHandling()
  }


  @Watch('appData', { immediate: true, deep: true })
  public async prepareData() {
    this.isLoading = true

    // dynamically import the module manager to enable code splitting
    // is already loaded when called from appAsid
    // not necessary as the individual vue app components are already loaded dynamically by getOverviewsTuple
    // const ModuleManagerApp = (await getModuleManagerApp()).default

    try {
      // only on initial page load get the background vues to prvent reexecuton when appling protection keys
      // todo to make this more egneric, compare the ne with the current data and apply a selective update
      if (this.initialLoad || this.liveUpdatePreviewMode)
        this.moduleGroupsBackgroundVue = ModuleManagerApp.getBackgroundTuple(this.appData.moduleGroupAppDatas)

      console.log(this.appData)
    } catch (e: any) {
      this.localErrorText += `An error occured [20220721]: ${e.toString()}`
      console.error(e)
    } finally {
      this.isLoading = false
      this.initialLoad = false
      await this.$nextTick()
      this.onRouteChanged()
    }
  }

  public onResetAppActiveLocales() {
    const browserLocales = (window.navigator.languages || [window.navigator.language]).map((l) =>
      l.toLowerCase()
    ) as locale[]

    this.setActiveLocales(browserLocales)
  }

  public async mounted() {
    this.initLinkHandler()
    this.initListenClickAppPreview()
    this.initResizeWatcher()
  }

  private resizeObserver?: ResizeObserver

  private initResizeWatcher() {
    // polyfill if required https://stackoverflow.com/questions/65819296/unhandled-runtime-error-referenceerror-cant-find-variable-resizeobserver-on-s
    if (!window.ResizeObserver) install()

    if (this.resizeObserver) this.resizeObserver.disconnect()

    // set container width for sizing the font. --widget-container-width-unitless * --scaler => font-size: clamp(min, scaler, max)
    const $ref = (this.$refs as any)['widget-container']
    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return
          }
          const width = '' + entry.contentRect.width
          document.documentElement.style.setProperty('--widget-container-width-unitless', width)
          // console.log('width', width)
          // console.log('height', entry.contentRect.height)
        })
      })
    })

    this.resizeObserver.observe($ref)
  }

  @Watch('$i18n.appActiveLocales', { immediate: true })
  public onActiveLocaleChanged() {
    CustomScriptExecution.eventLocaleChange.emit(this.$i18n.appActiveLocales)
  }

  private initLinkHandler() {
    // all internal links shall be processed by vue router to prevent reloading of the page
    // https://dennisreimann.de/articles/delegating-html-links-to-vue-router.html
    // only apply this to links that are under the .app-main element to not use for backend links
    const appElements = document.getElementsByClassName('app-main')

    if (appElements.length === 0) {
      console.warn('no app-main element found')
      return
    }

    setSingletClickEventListener(
      document,
      'app',
      'click',
      (event) => {
        // ensure the click happened on the app element
        if (!appElements[0] || !appElements[0].contains(event.target as any)) return

        // ensure we use the link, in case the click has been received by a subelement
        let target = event.target as any
        while (target && target.tagName !== 'A') target = target.parentNode
        // handle only links that do not reference external resources
        if (target && target.matches('a:not([href*=\'://\'])') && target.href) {
          // some sanity checks taken from vue-router:
          // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
          const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
          // don't handle with control keys
          if (metaKey || altKey || ctrlKey || shiftKey) return
          // don't handle when preventDefault called
          if (defaultPrevented) return
          // don't handle tel, mailto
          if ((target.href as string)?.startsWith('tel') || (target.href as string)?.startsWith('mail')) return
          // don't handle right clicks
          if (button !== undefined && button !== 0) return
          // don't handle if `target="_blank"`
          if (target && target.getAttribute) {
            const linkTarget = target.getAttribute('target')
            if (/\b_blank\b/i.test(linkTarget)) return
          }
          // don't handle same page links/anchors
          const url = new URL(target.href)
          const pathName = url.pathname
          const searchParams = url.search
          const hash = url.hash
          const urlPath = pathName + searchParams + hash

          if (window.location.pathname !== pathName && event.preventDefault) {
            event.preventDefault()
            console.log('setting app path', urlPath)
            setAppPath(urlPath)
          }
        }
      })
  }

  // #region preview
  /**
   * when the app is rendered in the backend as preview. various click & hover events are emitted to be able to show the respective setting in the bakcned
   */

  public initListenClickAppPreview() {
    PreviewEventBus.unsubscribeAll()

    PreviewEventBus.on({
      cb: (appClickedEvent) =>
        this.handleClickForPreview(appClickedEvent)
    })
  }

  public beforeDestroy() {
    PreviewEventBus.unsubscribeAll()
    if (this.resizeObserver) this.resizeObserver.disconnect()
    if (this.pathHandlingUnbindHandler) this.pathHandlingUnbindHandler()
  }

  public handleClickForPreview(appClickedEvent: AppClickedEvent) {
    this.$emit('selected', appClickedEvent)
  }
  // #endregion preview
}
</script>

<style lang="scss">
$widget-container-max-width-unitless: 600; // width in pixel. Dont use unit for css calc to work
$widget-container-min-width-unitless: 300; // width in pixel. Dont use unit for css calc to work
$reactive-font-margin-unitless: 100; // for how many px from upper and lower bound to keep the same fontsize

/* stylelint-disable-next-line value-keyword-case */
$default-font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

@function widgetVariableName($widget-type, $variable-name) {
  @return --_#{$widget-type}_-#{$variable-name};
}

html {
  scroll-behavior: smooth;
}

:root {
  /**
  * Workflow for new vars
  * 1. register var in this root section.
  *    use widgetNamingPrefixedVars() if its a var that exists on a widget as well al global basis and need to use a fallback value.
  *    doing so creates prefixed vars for --_inline_, --_tile_, ...
  * 2. in the place of use call fallbackVariable() to properly setup a fallback chain
  * 3. use the variable at its desired css property
  */

  // widget display style prefixes are used for ebaling fallback logic.
  // if 'button' or 'tile' style is not give, fallback to 'inline'
  @mixin widgetNamingPrefixedVars($variable-name, $default-value...) {
    #{widgetVariableName(base, $variable-name)}: $default-value; // resulting var name can be deducted by removing prefix
    #{widgetVariableName(inline, $variable-name)}: initial;
    #{widgetVariableName(tile, $variable-name)}: initial;
    // #{widgetVariableName(tile-alt, $variable-name)}: initial;
  }

  /** Some props only exist in the expanded form. E.g. highlight color */
  @mixin expandedWidgetNamingPrefixedVars($variable-name, $default-value...) {
    #{widgetVariableName(base, $variable-name)}: $default-value;
    #{widgetVariableName(inline, $variable-name)}: initial;
  }

  // naming higlevel > lowlevel: (style (inline, tile, ...)) > section (header, footer, widget, ...) > propery (color, margin-top, ...)
  // e.g. --inline_widget-title-font-size: 1rem
  @mixin widgetNamingPrefixedVarsTopLeftBottomRight($variable-name, $default-value) {
    @include widgetNamingPrefixedVars(#{$variable-name}-top, $default-value);
    @include widgetNamingPrefixedVars(#{$variable-name}-right, $default-value);
    @include widgetNamingPrefixedVars(#{$variable-name}-bottom, $default-value);
    @include widgetNamingPrefixedVars(#{$variable-name}-left, $default-value);
  }

  --header-color-background: #171717;
  --header-color-text: #fff;

  --body-color-inner-background: #484848;
  --body-color-outer-background: #626262;
  --body-padding-top: 1rem;
  --body-padding-bottom: 1rem;

  --footer-color-background: #fbfbfb;
  --footer-color-text: #4b4b4b;

  --text-font-size: 2rem;

  // #region widget

  @include widgetNamingPrefixedVars(widget-color-background, #e7e7e7);
  @include widgetNamingPrefixedVars(widget-color-background_rgb, 200, 200, 200);

  @include widgetNamingPrefixedVars(widget-box-shadow, initial);

  // spacing
  @include widgetNamingPrefixedVarsTopLeftBottomRight(widget-padding, 1.1rem);
  @include widgetNamingPrefixedVarsTopLeftBottomRight(widget-margin, 0.5rem);
  --_base_-widget-margin-left: 1rem;
  --_base_-widget-margin-right: 1rem;

  // border
  @include widgetNamingPrefixedVarsTopLeftBottomRight(widget-border-width, 0);
  @include widgetNamingPrefixedVars(widget-border-color, black);
  @include widgetNamingPrefixedVars(widget-border-radius, 0.7rem);

  @include widgetNamingPrefixedVars(widget-outline-color, initial);

  // #endregion widget

  // #region title
  /*
  * Widgets use title and subtitle
  * in html Module h1,h4 and title & subtitle are available
  * h1 is larger than title and h4 smaller than subtitle
  */

  // --heading-h1-font-size: 2.2rem; // title-size * 1.5
  // --heading-h2-font-size: 1.4rem; // title-size
  // --heading-h3-font-size: 1.2rem; // subtitle-size
  // --heading-h4-font-size: 1.1rem; // subtitle-size * 0.9

  @include widgetNamingPrefixedVars(widget-title-background-color, none);
  @include widgetNamingPrefixedVars(widget-subtitle-background-color, none);

  @include widgetNamingPrefixedVars(widget-title-color, black);
  @include widgetNamingPrefixedVars(widget-subtitle-color, none);

  @include widgetNamingPrefixedVars(widget-title-font-family, $default-font-family);
  @include widgetNamingPrefixedVars(widget-subtitle-font-family, $default-font-family);

  @include widgetNamingPrefixedVars(widget-title-font-size, 1.4rem);
  // --_button_-widget-title-font-size: 1.1rem;
  @include widgetNamingPrefixedVars(widget-subtitle-font-size, 1.2rem);

  @include widgetNamingPrefixedVars(widget-title-font-weight, 700);
  @include widgetNamingPrefixedVars(widget-subtitle-font-weight, 700);

  // spacing
  @include widgetNamingPrefixedVarsTopLeftBottomRight(widget-title-margin, initial);
  --_base_-widget-title-margin-bottom: 1rem;
  // --_button_-widget-title-margin-bottom: 0rem;
  @include widgetNamingPrefixedVarsTopLeftBottomRight(widget-title-padding, initial);

  @include widgetNamingPrefixedVarsTopLeftBottomRight(widget-subtitle-margin, initial);
  --_base_-widget-subtitle-margin-bottom: 1rem;

  @include widgetNamingPrefixedVarsTopLeftBottomRight(widget-subtitle-padding, initial);

  @include widgetNamingPrefixedVars(widget-tile-aspect-ratio, 1.177);
  @include widgetNamingPrefixedVars(widget-tile-gap-spacing, 1rem);
  @include widgetNamingPrefixedVars(widget-tile-title-vertical-alignment, flex-end);

  // alignment
  @include widgetNamingPrefixedVars(widget-title-text-align, initial);
  // --_button_-widget-title-text-align: center;
  // #endregion title

  // #region expanded (inline) widget button & highlight
  @include expandedWidgetNamingPrefixedVars(widget-button-color, #000);
  @include expandedWidgetNamingPrefixedVars(widget-button-background-color, #fff);

  @include expandedWidgetNamingPrefixedVars(widget-highlight-color, #fff);
  @include expandedWidgetNamingPrefixedVars(widget-highlight-background-color, #000);
  @include expandedWidgetNamingPrefixedVars(widget-highlight-background-color_rgb, 0, 0, 0);

  // #region text
  @include widgetNamingPrefixedVars(widget-text-background-color, initial);
  @include widgetNamingPrefixedVars(widget-text-color, #000);
  @include widgetNamingPrefixedVars(widget-text-color_rgb, 0, 0, 0);
  @include widgetNamingPrefixedVars(widget-text-font-family, $default-font-family);
  @include widgetNamingPrefixedVars(widget-text-font-size, 1rem);
  @include widgetNamingPrefixedVars(widget-text-font-weight, 400);
  // #endregion text

  // #endregion button & highlight

  // is overridden using js to correctly scale fonts
  // uniless for css calculations to work
  --widget-container-width-unitless: 400;
}

.app-main {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .app-body {
    // padding: 1rem 0.8rem;
    // min-height: calc(100vh - 18rem);
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    background: var(--body-color-outer-background);
  }

  .widget-container {
    flex: 1;
    overflow: hidden;
    background: var(--body-color-inner-background);
    margin: auto;
    max-width: calc(#{$widget-container-max-width-unitless} * 1px);
    width: 100%;

    padding-top: var(--body-padding-top);
    padding-right: 0;
    padding-bottom: var(--body-padding-bottom);
    padding-left: 0;

    .widget-container-overview,
    .widget-container-single {
      // position: relative;
      max-width: 100%;

      display: flex;
      flex-wrap: wrap;
      // column-gap: 1rem;

      section.widget-overview.widget-outer-wrapper,
      section.widget-single.widget-outer-wrapper {
        flex: 100%;
        max-width: 100%;

        // position: relative;
        // is 1 at max size and n at min size
        --linear-size-scaler:
          calc(
            1 / #{$widget-container-max-width-unitless} * var(--widget-container-width-unitless)
          );

        background: var(--widget-outline-color);

        // local variable are introduces for simpler CSS styling.
        // without a local variable which covers the fallback, all places where this variable shall be used, the fallback must also be applied
        // e.g. flex: calc(50% - var(--widget-tile-margin-right) - var(--widget-tile-margin-left)); for '--widget-tile-margin-right' we also need to introduce a fallback to '--widget-inline-margin-right'
        // use variable from specific widget type and fallback to 'inline'
        @mixin fallbackVariable($widget-type, $variable-name) {
          --#{$variable-name}:
            var(
              #{widgetVariableName($widget-type, $variable-name)},
              var(#{widgetVariableName(base, $variable-name)})
            );
        }

        @mixin expandedWidgetStyles($widget-type) {
          @include fallbackVariable($widget-type, widget-text-background-color);
          @include fallbackVariable($widget-type, widget-text-color);
          @include fallbackVariable($widget-type, widget-text-color_rgb);
          @include fallbackVariable($widget-type, widget-text-font-family);
          @include fallbackVariable($widget-type, widget-text-font-size);
          @include fallbackVariable($widget-type, widget-text-font-weight);

          @include fallbackVariable($widget-type, widget-button-color);
          @include fallbackVariable($widget-type, widget-button-background-color);

          @include fallbackVariable($widget-type, widget-highlight-color);
          @include fallbackVariable($widget-type, widget-highlight-background-color);
          @include fallbackVariable($widget-type, widget-highlight-background-color_rgb);
        }

        @mixin widgetStyles($widget-type) {
          @include fallbackVariable($widget-type, widget-color-background);
          @include fallbackVariable($widget-type, widget-color-background_rgb);
          @include fallbackVariable($widget-type, widget-box-shadow);
          @include fallbackVariable($widget-type, widget-border-radius);

          @include fallbackVariable($widget-type, widget-margin-top);
          @include fallbackVariable($widget-type, widget-margin-right);
          @include fallbackVariable($widget-type, widget-margin-bottom);
          @include fallbackVariable($widget-type, widget-margin-left);

          @include fallbackVariable($widget-type, widget-padding-top);
          @include fallbackVariable($widget-type, widget-padding-right);
          @include fallbackVariable($widget-type, widget-padding-bottom);
          @include fallbackVariable($widget-type, widget-padding-left);

          @include fallbackVariable($widget-type, widget-title-font-family);

          @include fallbackVariable($widget-type, widget-title-font-size);
          @include fallbackVariable($widget-type, widget-title-font-weight);
          @include fallbackVariable($widget-type, widget-title-color);
          @include fallbackVariable($widget-type, widget-title-background-color);

          @include fallbackVariable($widget-type, widget-title-margin-top);
          @include fallbackVariable($widget-type, widget-title-margin-right);
          @include fallbackVariable($widget-type, widget-title-margin-bottom);
          @include fallbackVariable($widget-type, widget-title-margin-left);

          @include fallbackVariable($widget-type, widget-title-padding-top);
          @include fallbackVariable($widget-type, widget-title-padding-right);
          @include fallbackVariable($widget-type, widget-title-padding-bottom);
          @include fallbackVariable($widget-type, widget-title-padding-left);

          @include fallbackVariable($widget-type, widget-title-text-align);

          @include fallbackVariable($widget-type, widget-subtitle-font-family);

          @include fallbackVariable($widget-type, widget-subtitle-font-size);
          @include fallbackVariable($widget-type, widget-subtitle-font-weight);
          @include fallbackVariable($widget-type, widget-subtitle-color);
          @include fallbackVariable($widget-type, widget-subtitle-background-color);

          @include fallbackVariable($widget-type, widget-subtitle-margin-top);
          @include fallbackVariable($widget-type, widget-subtitle-margin-right);
          @include fallbackVariable($widget-type, widget-subtitle-margin-bottom);
          @include fallbackVariable($widget-type, widget-subtitle-margin-left);

          @include fallbackVariable($widget-type, widget-subtitle-padding-top);
          @include fallbackVariable($widget-type, widget-subtitle-padding-right);
          @include fallbackVariable($widget-type, widget-subtitle-padding-bottom);
          @include fallbackVariable($widget-type, widget-subtitle-padding-left);

          @include fallbackVariable($widget-type, widget-border-width-top);
          @include fallbackVariable($widget-type, widget-border-width-right);
          @include fallbackVariable($widget-type, widget-border-width-bottom);
          @include fallbackVariable($widget-type, widget-border-width-left);

          @include fallbackVariable($widget-type, widget-border-color);

          @include fallbackVariable($widget-type, widget-outline-color);

          @include fallbackVariable($widget-type, widget-tile-aspect-ratio);
          @include fallbackVariable($widget-type, widget-tile-gap-spacing);
          @include fallbackVariable($widget-type, widget-tile-title-vertical-alignment);
        }

        .has-app-color-highlight {
          background: var(--widget-highlight-background-color);
          color: var(--widget-highlight-color);
        }

        // &.widget-style-tile-half {
        //   flex: 50%;
        //   max-width: 50%;

        //   & .widget-spacing-wrapper > .item-wrapper-content {
        //     padding-bottom: 85%;
        //   }
        // }

        // &.widget-style-tile-full {
        //   flex: 100%;
        //   max-width: 100%;

        //   & > .widget-spacing-wrapper > .item-wrapper-content {
        //     padding-bottom: 42.5%;
        //   }
        // }

        &.widget-style-tile-half {
          --aspect-ratio: var(--widget-tile-aspect-ratio);
        }

        &.widget-style-tile-full {
          --aspect-ratio: calc(2 * var(--widget-tile-aspect-ratio));
        }

        &.widget-style-tile-half,
        &.widget-style-tile-full {
          // move mudule title in tile a bit closer to subtitle fot backwards compatablity
          .module-title {
            margin-bottom: calc(var(--widget-title-margin-bottom) - 0.5rem);
          }

          // move mudule titletitle in tile a bit down for backwards compatablity
          .module-subtitle {
            margin-bottom: calc(var(--widget-subtitle-margin-bottom) - 0.5rem);
          }

          & .widget-spacing-wrapper {
            // height: calc(var(--widget-tile-height) * var(--linear-size-scaler) * 0.9);
            // --widget-tile-aspect-ratio: calc(2 / 1);

            // aspect-ratio: var(--aspect-ratio); => does not produce exactly the desired result + not yet supported on all browsers

            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            &::before {
              float: left;
              // adjust inner ratio helper to create the requested ratio when the defined padding is also applied
              // h: height, w:width, hp:height-padding, wp:width (100%), pl: padding-left,...
              // h = w / ratio
              // w = wp + pl + pr
              // hp = h - pt - pb
              --pt: var(--widget-padding-top);
              --pb: var(--widget-padding-bottom);
              --pl: var(--widget-padding-left);
              --pr: var(--widget-padding-right);
              --wp: 100%;
              padding-top: calc((var(--wp) + var(--pl) + var(--pr)) / var(--aspect-ratio) - var(--pt) - var(--pb));
              // padding-top: calc(calc(1 / var(--widget-tile-aspect-ratio) * (100% - var(--widget-padding-right) - var(--widget-padding-left))) - var(--widget-padding-top));
              // padding-top: calc( - var(--widget-padding-top) - var(--widget-padding-bottom) + calc(1 / var(--widget-tile-aspect-ratio) * (100% - var(--widget-padding-right) - var(--widget-padding-left))));
              content: '';
            }

            &::after {
              display: block;
              content: '';
              clear: both;
            }
          }
        }

        &.widget-style-tile-half {
          flex: 50%;
          max-width: 50%;
        }

        &.widget-style-tile-full {
          flex: 100%;
          max-width: 100%;
        }

        // for widget inline & single, the latter does not have a dedicated tile class
        &.widget-style-inline,
        &.widget-style-preview-single {
          @include widgetStyles(inline);
          @include expandedWidgetStyles(inline);
        }

        // this trickery looks strange when unequal side margin is used.
        // half-tiles will have different heights => cant optimice for every condition. User must set center-spacing accordingly
        &.widget-style-tile-half {
          &.widget-position-left {
            .widget-spacing-wrapper {
              margin-right: calc(var(--widget-tile-gap-spacing) / 2);
            }
          }

          &.widget-position-right {
            .widget-spacing-wrapper {
              margin-left: calc(var(--widget-tile-gap-spacing) / 2);
            }
          }
        }

        &.widget-style-tile-half {
          &.widget-position-left {
            padding-right: 0;
          }

          &.widget-position-right {
            padding-left: 0;
          }
        }

        // todo havng individual variable per widget type might be bad for styling ,cince now all styles like color have to listen to all possible vars
        &.widget-style-tile-full,
        &.widget-style-tile-half {
          @include widgetStyles(tile);
          @include expandedWidgetStyles(tile);

          cursor: pointer;

          .module-title-wrapper {
            position: absolute;
            // bottom: 1.5rem;
            // padding: 0rem 1rem;
            /* line-height: 2rem; */
            display: flex;
            flex-direction: column;
            justify-content: var(--widget-tile-title-vertical-alignment);

            height: calc(100% - var(--widget-padding-top) - var(--widget-padding-bottom));
            width: calc(100% - var(--widget-padding-right) - var(--widget-padding-left));
          }

          .module-title {
            // margin-bottom: 0.1rem;
          }
        }

        &.widget-style-button {
          @include widgetStyles(inline);
          @include expandedWidgetStyles(inline);
        }
      }

      section.widget-style-button .widget-spacing-wrapper .module-description {
        margin-top: 0 !important;
      }

      section.widget-overview .widget-spacing-wrapper,
      section.widget-single .widget-spacing-wrapper {
        flex: 100%;

        font-weight: var(--widget-text-font-weight);
        font-family: custom-text-font, var(--widget-text-font-family);
        font-size: var(--widget-text-font-size);
        color: var(--widget-text-color);

        background: var(--widget-color-background);
        box-shadow: var(--widget-box-shadow);

        margin-top: var(--widget-margin-top);
        margin-right: var(--widget-margin-right);
        margin-bottom: var(--widget-margin-bottom);
        margin-left: var(--widget-margin-left);

        padding-top: var(--widget-padding-top);
        padding-right: var(--widget-padding-right);
        padding-bottom: var(--widget-padding-bottom);
        padding-left: var(--widget-padding-left);

        border-top-width: var(--widget-border-width-top);
        border-right-width: var(--widget-border-width-right);
        border-bottom-width: var(--widget-border-width-bottom);
        border-left-width: var(--widget-border-width-left);
        border-radius: var(--widget-border-radius);
        border-color: var(--widget-border-color);
        border-style: solid;

        position: relative;

        // visible to make ":after outline workaround" possible
        overflow: visible;

        // // helper to make sharp edged outline possible
        // &:after {
        //   content: '';
        //   display: block;
        //   width: 100%;
        //   height: 100%;
        //   position: absolute;
        //   top: 0;
        //   left: 0;

        //   outline-color: var(--widget-outline-color);
        //   outline-style: var(--widget-outline-style);
        //   outline-width: var(--widget-outline-width);
        // }

        &:not(.widget-style-tile-half, .widget-style-tile-full, .no-widget-padding) {
          a.button,
          input.button {
            border-radius: 0.6rem;

            &:hover {
              filter: brightness(95%);
            }
          }
        }

        // used for button style
        &.no-widget-padding {
          padding: 0;
          border: 0;
          background: var(--widget-outline-color);
        }

        &.widget-style-button {
          // padding-top: 0;
          // padding-right: 0;
          // padding-bottom: 0;
          // padding-left: 0;
        }

        a {
          color: var(--widget-text-color);
          text-decoration: underline;
        }

        hr {
          background-color: var(--widget-text-color);
          border-color: var(--widget-text-color);
        }

        a.button,
        input.button,
        .has-button-style {
          color: var(--widget-button-color);
          background: var(--widget-button-background-color);
          font-family: inherit;
          font-weight: bold;
          border-radius: 0.7rem;
          border-color: rgb(0 0 0 / 15%);

          &:not(.custom-button-style) * {
            color: var(--widget-button-color);
          }
        }

        a.button {
          width: 100%;
          padding: 1.5rem;
          // TODO do wen need th a customized padding here?
          // padding-top: var(--button-padding-top);
          // padding-right: var(--button-padding-right);
          // padding-bottom: var(--button-padding-bottom);
          // padding-left: var(--button-padding-left);
          text-decoration: none;
        }

        /**
  * reactiveFontSize(--some-css-var-name)
  */
        @mixin reactiveFontSize($min-font-size-css-var) {
          // font-size: 1.4rem;
          // https://css-tricks.com/simplified-fluid-typography/
          // 1. define min fontSize at 285 px 2. calculate scaler 3. calc max
          // keep the calculation in CSS vars, since the vars are changes based on tenants Ci
          --min-font-size: var(#{$min-font-size-css-var});
          // FSmin / Cmin * Cmax = max
          --max-font-size:
            calc(
              var(--min-font-size) / #{$widget-container-min-width-unitless + $reactive-font-margin-unitless} * #{$widget-container-max-width-unitless - $reactive-font-margin-unitless}
            );
          // FSmin / Cmin * Cwidth = scaler
          // clamp to include the reactive-font-margin
          --font-size:
            clamp(
              var(--min-font-size),
              calc(
                (var(--min-font-size) / (#{$widget-container-min-width-unitless} + #{$reactive-font-margin-unitless})) * var(--widget-container-width-unitless)
              ),
              var(--max-font-size)
            ); // widget-container 285px-600px (e.g. 416px*0.01*5 => 20.8px)

          font-size: var(--font-size);
          line-height: calc(var(--font-size) * 1.2);
        }

        h1,
        h2,
        h3,
        h4,
        h6 {
          margin-bottom: 0.5rem;
          box-decoration-break: clone;
        }

        .module-title-wrapper {
          text-align: var(--widget-title-text-align);

          .module-title {
            // display: inline-block;
            // line-height: 1.2rem;
          }
        }

        .module-subtitle {
          // t
        }

        h1,
        .title.is-1 {
          --heading-h1-font-size: calc(var(--widget-title-font-size) * 1.5);
          @include reactiveFontSize(--heading-h1-font-size);
          font-weight: var(--widget-title-font-weight);
        }

        /** module title is split into individual spans for correct hyphenation */
        h2:not(.module-title),
        .title.is-2,
        .module-title span {
          @include reactiveFontSize(--widget-title-font-size);

          font-weight: var(--widget-title-font-weight);
          font-family: custom-title-font, var(--widget-title-font-family);
          color: var(--widget-title-color);
          background: var(--widget-title-background-color);
        }

        h2.module-title {
          font-family: none;
        }

        .module-title {
          .title-word-wrapper {
            line-height:
              calc(
                var(--font-size) * 1 + 0.8 * (var(--widget-title-padding-top) + var(--widget-title-padding-bottom))
              );
            padding-top: var(--widget-title-padding-top);
            padding-right: var(--widget-title-padding-right);
            padding-bottom: var(--widget-title-padding-bottom);
            padding-left: var(--widget-title-padding-left);

            margin-left: calc(-0.07 * var(--font-size));

            .module-title-word {
              padding-left: calc(0.07 * var(--font-size));
              padding-right: calc(0.19 * var(--font-size));
              margin-right: calc(-0.05 * var(--font-size));
              background: none;
              background: var(--widget-title-background-color);
            }

            box-decoration-break: clone;
            // &:first-child {
            //   padding-left: var(--widget-title-padding-left);
            // }
            // &:last-child {
            //   padding-right: var(--widget-title-padding-right);
            // }
          }
        }

        h2:not(.module-title),
        .title.is-2,
        .module-title {
          margin-top: var(--widget-title-margin-top);
          margin-right: var(--widget-title-margin-right);
          margin-bottom: var(--widget-title-margin-bottom);
          margin-left: var(--widget-title-margin-left);
        }

        .module-title-wrapper {
          // display: flex;
          // .module-title {
          //   flex-grow: 1;
          // }
        }

        h3,
        .title.is-3,
        .module-subtitle {
          @include reactiveFontSize(--widget-subtitle-font-size);
          font-weight: var(--widget-subtitle-font-weight);
          font-family: custom-subtitle-font, var(--widget-subtitle-font-family);
          color: var(--widget-subtitle-color);
          background: var(--widget-subtitle-background-color);

          margin-top: var(--widget-subtitle-margin-top);
          margin-right: var(--widget-subtitle-margin-right);
          margin-bottom: var(--widget-subtitle-margin-bottom);
          margin-left: var(--widget-subtitle-margin-left);

          padding-top: var(--widget-subtitle-padding-top);
          padding-right: var(--widget-subtitle-padding-right);
          padding-bottom: var(--widget-subtitle-padding-bottom);
          padding-left: var(--widget-subtitle-padding-left);
        }

        .subtitle-fontsize {
          @include reactiveFontSize(--widget-subtitle-font-size);
        }

        h4,
        .title.is-4 {
          --heading-h4-font-size: calc(var(--widget-subtitle-font-size) * 0.9);
          @include reactiveFontSize(--heading-h4-font-size);
          font-weight: var(--widget-subtitle-font-weight);
        }

        .module-description {
          white-space: pre-wrap;
          margin-top: -0.5rem;
          margin-bottom: 1rem;

          display: inline-block;

          color: var(--widget-text-color);
        }
      }
    }
  }

  .has-app-color-widget-contrast {
    color: var(--widget-text-color) !important;
  }

  .has-app-color-widget {
    color: var(--widget-color-background) !important;
  }

  .header {
    div.header-logo-container-padding {
      padding: 1.5rem;

      .default-logo {
        display: table; // to center it
        margin: auto;
      }
    }
  }

  .footer {
    background: var(--footer-color-background);
    color: var(--footer-color-text);

    padding: 0 0 1.5rem;

    *,
    .button {
      color: var(--footer-color-text);
    }

    .legal-links {
      margin: 0.5rem;
    }

    .language-select {
      padding: 1rem 1.5rem;
      margin: auto;
      // margin-bottom: 1rem; TODO add back in after visual test
      max-width: calc(#{$widget-container-max-width-unitless} * 1px);

      a {
        padding: 0.4rem;
        text-decoration: none;

        &.active {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
