<template>
  <section style="position: relative;">
    <h3
      v-if="$i18n.getLocalString(moduleAppData.group.title, $i18n.appActiveLocales)"
      class="module-title"
    >{{ $i18n.getLocalString(moduleAppData.group.title, $i18n.appActiveLocales) }}</h3>
    <p
      v-if="$i18n.getLocalString(moduleAppData.group.description, $i18n.appActiveLocales)"
      class="module-description"
    >{{ $i18n.getLocalString(moduleAppData.group.description, $i18n.appActiveLocales) }}</p>
    <section class="loading-element-placeholder" />

    <b-loading :is-full-page="false" :active="true" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSearch, faFilter
} from '@fortawesome/free-solid-svg-icons'

import { asidID } from '../../types/typeAsid'
import { BaseModuleGroupAppData } from '@/modules/typeModules'


library.add(faSearch, faFilter)

@Component({
  components: {}
})
export default class AppGroupLoading extends Vue {
  @Prop({ type: Object, required: true }) readonly moduleAppData!: BaseModuleGroupAppData

  @Prop({ type: Boolean, required: false, default: () => false }) readonly isOverview!: boolean

  @Prop({ type: String, required: false, default: () => 'test-asid' }) readonly asid!: asidID

  public created() {
    //
  }
}
</script>

<style lang="scss">
.loading-element-placeholder {
  padding: 5em;
}
</style>
