import { ModuleType } from '@/modules/typeModules'
import { RouteConfig } from 'vue-router'
import BaseModuleApp from '@/modules/baseModuleApp'
import AppGroupLoading from '@/pages/app/App_Group_Loading.vue'
import { LocalizedField, LocalizedFieldObject } from '@/types/typeI18n'
import { I18nGlobalsInst } from '../_globals/i18nGlobals'

const App_Module_File_View = import(/* webpackChunkName: "App_Module_File_View" */ './App_Module_File_View.vue')

export default class FileModuleApp extends BaseModuleApp {
  public static type: ModuleType = 'File'

  private static youtubeParser(url: string) {
    const YOUTUBE_URL_REGEX = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const match = url.match(YOUTUBE_URL_REGEX)
    url = match && match[7].length === 11 ? match[7] : ''
    return url
  }

  public static isYoutubeUrl(url?: string) {
    if (!url) return false
    return this.youtubeParser(url).length > 0
  }

  public static createEmbedUrl(urlOrId: string) {
    return 'https://www.youtube.com/embed/' + (urlOrId.length === 11 ? urlOrId : this.youtubeParser(urlOrId))
  }

  public static getStartPage(fileUrls: LocalizedField, startPages: LocalizedFieldObject<number>) {
    const localeOfPdfFile = I18nGlobalsInst.getLocalizedTextLocale(fileUrls)
    const selectedFilePdfStartPage = localeOfPdfFile in startPages.locales ? startPages.locales[localeOfPdfFile] : 1
    return +selectedFilePdfStartPage
  }

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'file/:groupID',
        name: 'file',
        component: () => App_Module_File_View
      }
    ]
  }

  public static registerOverviewVue() {
    return () => ({
      component: App_Module_File_View,
      loading: AppGroupLoading
    })
  }
}
