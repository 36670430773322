import Router, { RawLocation, RouteConfig } from 'vue-router'

import {
  ModuleType,
  ALL_MODULE_TYPE_NAMES,
  BaseModuleGroupAppData,
  BaseGroupAppData,
  BaseElementAppData
} from './typeModules'
import { functions } from '@/firebaseApp'

import AppGroupLoading from '@/pages/app/App_Group_Loading.vue'
const AppLegal = () => import(/* webpackChunkName: "App_Legal" */ '@/pages/app/App_Legal.vue')
import BaseModuleApp from './baseModuleApp'

import FormModuleApp from './form/formModuleApp'

import CiModuleApp from './ci/ciModuleApp'
import HtmlModuleApp from './html/htmlModuleApp'
import ScriptModuleApp from './script/scriptModuleApp'
import I18nModuleApp from './i18n/i18nModuleApp'
import FileModuleApp from './file/fileModuleApp'
import ProtectionModuleApp from './protection/protectionModuleApp'
import CustomModuleApp from './custom/customModuleApp'
import { VueImport } from '@/types/typeVueComponents'
import { AppWhitelabelConfig } from '@/types/typeAppWhitelabelConfig'
import { getSessionID, setSessionID } from './sessionApp'
import { objectID } from '@/types/typeGeneral'
import { RPCAppDataRequest, RPCAppDataResponse } from '@/types/typeRPC'
import { LegalConfig } from '@/types/typeAppConfig'
import ServiceModuleApp from './service/serviceModuleApp'
import { httpsCallable } from 'firebase/functions'
import LinkModuleApp from './link/linkModuleApp'

export interface ModuleAppDataVueComponentTuple {
  component: VueImport
  moduleAppData: BaseModuleGroupAppData
  type: ModuleType
  props: { [propName: string]: string }
}

const ALL_APP_MODULE_ClASSES = [
  FormModuleApp,
  FileModuleApp,
  CiModuleApp,
  HtmlModuleApp,
  ScriptModuleApp,
  CustomModuleApp,
  I18nModuleApp,
  ProtectionModuleApp,
  ServiceModuleApp,
  LinkModuleApp
]

export default abstract class ModuleManagerApp {
  public static readonly moduleClasses = ALL_APP_MODULE_ClASSES // todo make this private?
  private static readonly moduleClassesByType = ModuleManagerApp.moduleClasses.reduce(
    (res, mod) => {
      res[mod.type] = mod
      return res
    },
    {} as {
      [key: string]: typeof ModuleManagerApp.moduleClasses[0]
    }
  )

  public static availableModules: readonly ModuleType[] = ALL_MODULE_TYPE_NAMES

  public static getRoutes(): RouteConfig[] {
    const routes: RouteConfig[] = []

    for (const module of this.moduleClasses) {
      routes.push(
        ...module.getRoutes().map((R) => ({
          ...R,
          meta: {
            moduleType: module.type
          }
        }))
      )
    }
    return routes
  }

  private static getModuleClassByType(type: ModuleType) {
    return this.moduleClassesByType[type]
  }

  public static getAppData(asid: objectID, accessKeys: string[] = []): Promise<RPCAppDataResponse> {
    const appDataRPC = httpsCallable(functions, 'appDataRPC')
    const sessionId = getSessionID()

    // to allow for even smaller qr codes with alphanumeric encoding, which will be all uppercase
    asid = asid.toLowerCase()

    const appDataRequest: RPCAppDataRequest = { asid, accessKeys, sessionId }

    return appDataRPC(appDataRequest).then((response) => {
      console.log(response)
      const appData = response.data as RPCAppDataResponse
      setSessionID(appData.globalData.sessionID)

      return appData
    })
  }

  public static getWhitelabelConfig(hostname: string): Promise<AppWhitelabelConfig> {
    const appWhitelabelConfig = httpsCallable(functions, 'getAppWhitelabelConfigRPC')
    return appWhitelabelConfig({ hostname }).then((response) => {
      console.log(response)

      return response.data as AppWhitelabelConfig
    })
  }

  public static getOverviewsTuple<T extends BaseModuleGroupAppData>(
    appData: T[]
  ): Array<ModuleAppDataVueComponentTuple> {
    return this.buildModuleAppDataVueComponentTuple(appData, (mod, appData) => {
      return mod.registerOverviewVue()
    })
  }

  private static getRouter() {
    return new Router({
      mode: 'abstract',
      base: process.env.BASE_URL,
      linkExactActiveClass: 'is-active',
      routes: [
        ...ModuleManagerApp.getRoutes().map((c) => ({ ...c, path: '/' + c.path })), // Module type is passed as meta (moduleType: 'Form')
        {
          path: '',
          name: 'asid',
          props: true
        },
        {
          path: '/legal/:type',
          name: 'legal',
          component: () => ({
            component: AppLegal(),
            loading: AppGroupLoading
          }),
          props: true,
          meta: {
            moduleType: 'legal'
          }
        },
        {
          path: '/page/:pageID',
          name: 'page',
          props: true
        }
      ]
    })
  }

  public static getPath(to: RawLocation) {
    return this.getRouter().resolve(to).href || ''
  }

  // filter app data for specific module type and groupID
  public static getFilteredAppDataGroup<T extends BaseModuleGroupAppData>(
    appData: RPCAppDataResponse,
    moduleType: ModuleType,
    groupID: string
  ): T | undefined {
    return appData.moduleGroupAppDatas.find((ad) => ad.public.type === moduleType && ad.group.id === groupID) as T
  }

  public static getGroupIDForElementID(appData: RPCAppDataResponse, moduleType: ModuleType, elementID: string): string {
    return (
      appData.moduleGroupAppDatas.find(
        (ad) => ad.public.type === moduleType && ad.elements.find((e) => e.id === elementID)
      )?.group.id || ''
    )
  }

  public static getGroupsForGroupIDs<T extends BaseGroupAppData>(
    appData: RPCAppDataResponse,
    moduleType: ModuleType,
    groupIDs: string[]
  ): T[] {
    return groupIDs
      .map((groupID) => this.getFilteredAppDataGroup(appData, moduleType, groupID))
      .filter((el) => el)
      .map((el) => el?.group as unknown as T)
  }

  public static getGroupForGroupID<T extends BaseGroupAppData>(
    appData: RPCAppDataResponse,
    moduleType: ModuleType,
    groupID: string
  ): T {
    return this.getFilteredAppDataGroup(appData, moduleType, groupID)?.group as T
  }

  public static getElementForElementID<T extends BaseElementAppData>(
    appData: RPCAppDataResponse,
    moduleType: ModuleType,
    elementID: string
  ): T | undefined {
    return appData.moduleGroupAppDatas
      .filter((ad) => ad.public.type === moduleType)
      .flatMap((ad) => ad.elements)
      .find((el) => el.id === elementID) as unknown as T
  }

  public static getElementsForElementIDs<T extends BaseElementAppData>(
    appData: RPCAppDataResponse,
    moduleType: ModuleType,
    elementIDs: string[]
  ): T[] {
    return elementIDs
      .map((elementID) => this.getElementForElementID<T>(appData, moduleType, elementID))
      .filter((el) => el) as T[]
  }

  /**
   * expand the autotile to a full tile to fill gaps in the layout
   * also swap it one position back if necessary (ahhh => fhhh => hfhh)
   */
  private static processAutomaticTile(
    groups: (ModuleAppDataVueComponentTuple & { localData?: { position: 'left' | 'right' } })[]
  ) {
    let sectionStartPointer = 0
    let sectionWidgetCount = 0
    // console.log(groups.map(g => g.moduleAppData.group.display.displayType))
    for (let i = 0; i < groups.length; i++) {
      const widgetType = groups[i].moduleAppData.group.display.displayType

      if (['tile-half', 'tile-auto'].includes(widgetType)) {
        sectionWidgetCount++
      }

      groups[i]['localData'] = { position: sectionWidgetCount % 2 !== 0 ? 'left' : 'right' }

      if (!['tile-half', 'tile-auto'].includes(widgetType) || groups.length - 1 === i) {
        const sectionEndPointer = sectionStartPointer + sectionWidgetCount - 1
        // process section
        if (sectionWidgetCount > 0) {
          let autoTileExpanded = false
          let tileBeforeAutoTileCounter = 0

          for (let j = sectionEndPointer; j >= sectionStartPointer; j--) {
            if (sectionWidgetCount % 2 === 0 || autoTileExpanded) {
              groups[j].moduleAppData.group.display.displayType = 'tile-half'
            } else if (!autoTileExpanded && groups[j].moduleAppData.group.display.displayType === 'tile-auto') {
              groups[j].moduleAppData.group.display.displayType = 'tile-full'
              autoTileExpanded = true

              if (tileBeforeAutoTileCounter % 2 !== 0) {
                const tempWidget = groups[j]
                groups[j] = groups[j + 1]
                groups[j + 1] = tempWidget
              }
            }
            tileBeforeAutoTileCounter++
          }
          // console.log(sectionStartPointer, ':', sectionStartPointer + sectionWidgetCount - 1)
        }
        sectionWidgetCount = 0
        sectionStartPointer = i + 1
      }
    }

    // console.log(groups.map(g => g.moduleAppData.group.display.displayType))
    return groups as (ModuleAppDataVueComponentTuple & { localData: { position: 'left' | 'right' } })[]
  }

  public static getRouteTuples(
    appData: RPCAppDataResponse,
    path: string
  ): {
      type: 'page' | 'legal' | 'widget'
      tuples: (ModuleAppDataVueComponentTuple & { localData?: { position: 'left' | 'right' } })[]
    } {
    const router = this.getRouter()
    const matchedRoute = router.resolve(path).route

    // if matches a page, return all appdata where the pageID matches the page param
    if (matchedRoute.name === 'page' || matchedRoute.name === 'asid') {
      const pageID = matchedRoute.params.pageID || ''
      const pageConfig = appData.globalData.pages.find((p) => p.id === pageID)

      const appDataForRoute = appData.moduleGroupAppDatas.filter((ad) => ad.group.pageID === pageID)
      if (!pageConfig && matchedRoute.name !== 'asid') throw `page ${pageID} is not active`

      return {
        type: 'page',
        tuples: this.processAutomaticTile(this.getOverviewsTuple(appDataForRoute))
      }
    }

    let component = router.getMatchedComponents(matchedRoute)[0] as VueImport

    if (!component) throw 'no component found [202310233]'

    let moduleType: ModuleType = matchedRoute.meta.moduleType

    // // legal is no real module, but rather gloal data which is then renders as module
    if (matchedRoute.name === 'legal') {
      return {
        type: 'legal',
        tuples: [
          {
            moduleAppData: appData.globalData.legal[matchedRoute.params.type as keyof LegalConfig] as any,
            type: 'Legal' as ModuleType,
            component,
            props: {}
          }
        ]
      }
    }

    // find the app data that matches the given route and module
    const groupsAppDatas = appData.moduleGroupAppDatas

    let appDataForRoute = groupsAppDatas.find(
      (ad) => ad.public.type === moduleType && ad.group.id === matchedRoute.params.groupID
    )

    // if no macthing element was found, check if it may be protected and thus switched for a protection element
    if (!appDataForRoute) {
      moduleType = 'Protection'
      component = ProtectionModuleApp.registerOverviewVue()
      appDataForRoute = groupsAppDatas.find(
        (ad) => ad.public.type === 'Protection' && ad.group.id === matchedRoute.params.groupID
      )
    }

    if (!appDataForRoute) throw `module ${matchedRoute.meta.moduleType} is not active for this id`

    return {
      type: 'widget',
      tuples: [
        {
          type: moduleType,
          moduleAppData: appDataForRoute,
          component,
          props: matchedRoute.params
        }
      ]
    }
  }

  public static getBackgroundTuple<T extends BaseModuleGroupAppData>(
    appData: T[]
  ): Array<ModuleAppDataVueComponentTuple> {
    return this.buildModuleAppDataVueComponentTuple(appData, (mod) => mod.registerBackgroundVue())
  }

  private static buildModuleAppDataVueComponentTuple(
    appData: BaseModuleGroupAppData[],
    doRegisterAndGetVueFile: (module: typeof BaseModuleApp, appData: BaseModuleGroupAppData) => VueImport
  ): Array<ModuleAppDataVueComponentTuple> {
    const tmpData = []
    for (const baseModuleGroupAppData of appData) {
      // if groupType is group, the module is a group and thus has no direct vue component
      if (baseModuleGroupAppData.group.groupType === 'group-type_group') continue

      const type = baseModuleGroupAppData.public.type
      const moduleAppData = baseModuleGroupAppData
      const component = doRegisterAndGetVueFile(this.getModuleClassByType(type as ModuleType), moduleAppData)

      if (component)
        tmpData.push({
          type: type as ModuleType,
          moduleAppData,
          component,
          props: {}
        })
    }
    return tmpData
  }
}
