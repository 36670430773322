import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import 'firebase/compat/functions'
// import 'firebase/compat/messaging'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID
}

// if (process.env.NODE_ENV === 'development') firebase.firestore.setLogLevel('debug')

// const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {})
// const client = new EchoServiceClient('http://myapi.com')
// enableDevTools([client])

const app = firebase.initializeApp(firebaseConfig)
const db = app.firestore()
const storage = app.storage()
const functions = app.functions('europe-west3')
// const messaging = firebase.messaging()
firebase.auth().languageCode = 'en'

console.log('VUE_APP_FIREBASE_ENV', process.env.VUE_APP_FIREBASE_ENV)

if (
  process.env.VUE_APP_FIREBASE_ENV === 'emulator'
  // &&
  // (typeof window === 'undefined' ||
  //   ['localhost', '127.0.0.1', process.env.VUE_APP_FIREBASE_EMULATOR_DOMAIN].includes(window.location.hostname))
) {
  // Note that the Firebase Web SDK must connect to the WebChannel port
  console.warn('using firebase emulator')
  console.log(firebaseConfig)
  // firebase.firestore.setLogLevel('debug')
  firebase.auth().useEmulator(`http://${process.env.VUE_APP_FIREBASE_EMULATOR_DOMAIN}:9099/`)

  functions.useEmulator(`${process.env.VUE_APP_FIREBASE_EMULATOR_DOMAIN}`, 5001)
  // functions.useFunctionsEmulator(`//${process.env.VUE_APP_FIREBASE_EMULATOR_DOMAIN}:5001`)
  storage.useEmulator(`${process.env.VUE_APP_FIREBASE_EMULATOR_DOMAIN}`, 9199)
  db.useEmulator(`${process.env.VUE_APP_FIREBASE_EMULATOR_DOMAIN}`, 5002)
  // db.useEmulator('localhost', 8080)
  // db.settings({
  //   host: process.env.VUE_APP_FIREBASE_EMULATOR_DOMAIN + ':5002',
  //   ssl: false,
  //   experimentalForceLongPolling: true // to fix cypress. todo may be changed to experimentalAutoDetectLongPolling
  // })
}

function throwOnChange(obj: any, prop: any) {
  Object.defineProperty(obj, prop, {
    value: obj[prop],
    writable: false
  })
}

// to prevent making them reactive by accident
throwOnChange(firebase, '__ob__')
throwOnChange(app, '__ob__')
throwOnChange(db, '__ob__')
throwOnChange(storage, '__ob__')
throwOnChange(functions, '__ob__')

export default db
export { firebase, app, storage, functions }
// export { firebase, app, storage, functions, messaging }

// Export types that exists in Firestore

export const Timestamp = firebase.firestore.Timestamp
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp
export const increment = firebase.firestore.FieldValue.increment
export const FieldValue = firebase.firestore.FieldValue

export class DocNotFound extends Error {
  constructor(docSnap: firebase.firestore.DocumentSnapshot) {
    super(`Document ${docSnap.ref.path} was not found`)
  }
}

// export const tenantRef = db //todo
//          .collection('Tenants')
//          .where('Users', 'array-contains', firebase.auth().currentUser)
