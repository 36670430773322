/* eslint-disable @typescript-eslint/no-empty-function */
import { ModuleType, BaseResponseAppData, BaseResponseItemAppData, BaseAddResponseRequestData } from './typeModules'
import { RouteConfig } from 'vue-router'

import { functions } from '@/firebaseApp'
import { asidID } from '@/types/typeAsid'
import { TenantID } from '@/types/typeTenant'
import { getappUploadPathModule } from '@/helpers/storageHelper'

import { VueImport } from '@/types/typeVueComponents'
import { getSessionID } from './sessionApp'
import { objectID } from '@/types/typeGeneral'
import {
  RPCAddResponsesRequest,
  RPCAddResponsesResponse,
  RPCGetResponseItemsRequest,
  RPCGetResponseItemsResponse,
  RPCGetResponsesRequest,
  RPCGetResponsesResponse,
  RPC_partial_Response
} from '@/types/typeRPC'
import { locale } from '@/types/typeI18n'
import { httpsCallable } from 'firebase/functions'
import CustomScriptExecution from './custom/customModuleUtils'

export default abstract class BaseModuleApp {
  public static type: ModuleType

  public static getUploadPath(tenantId: TenantID) {
    return getappUploadPathModule(tenantId, this.type.toLowerCase())
  }

  public static addResponse<T extends BaseAddResponseRequestData>(
    tenantId: TenantID,
    elementID: objectID,
    asidId: asidID,
    locale: locale,
    response: T,
    responseID?: objectID
  ) {
    return this.addResponsesHelper(tenantId, asidId, this.type, [{ response, elementID, responseID, locale }])
  }

  public static addResponses<T extends BaseAddResponseRequestData>(
    tenantId: TenantID,
    asidId: asidID,
    responses: RPC_partial_Response<T>[]
  ) {
    return this.addResponsesHelper(tenantId, asidId, this.type, responses)
  }

  protected static async addResponsesHelper<T extends BaseAddResponseRequestData>(
    tenantId: TenantID,
    asidID: asidID,
    moduleType: ModuleType,
    responses: RPC_partial_Response<T>[]
  ) {
    const addResponses = httpsCallable(functions, 'addResponsesRPC')

    // to allow for even smaller qr codes with alphanumeric encoding, which will be all uppercase
    asidID = asidID.toLowerCase()

    const responseSession = getSessionID()
    const rpcData: RPCAddResponsesRequest<T> = {
      tenantID: tenantId,
      asidID,
      moduleType,
      responseSession,
      responses
    }

    // emit event to process in custom or script module
    rpcData.responses.forEach((response) => {
      CustomScriptExecution.eventResponseCreated.emit({
        elementID: response.elementID,
        asidID: asidID,
        moduleType: moduleType,
        responseSession: responseSession,
        locale: response.locale,
        response: response.response
      })
    })

    // when using the preview asid

    if (rpcData.asidID === 'previ-ew111-echo1-code1') {
      console.log('not creating RSP for dummy asid', rpcData)
      return Promise.resolve({
        responseIDs: [],
        responseItemIDs: [],
        responseTransactionID: '',
        status: 'not called due to preview mode'
      } as RPCAddResponsesResponse)
    }

    const addResponseResponse = await addResponses(rpcData)
    return addResponseResponse.data as RPCAddResponsesResponse
  }

  protected static async getResponseItemsHelper<T extends BaseResponseItemAppData>(
    elementID: objectID,
    tenantID: TenantID,
    asidID: asidID,
    responseID: objectID
  ) {
    const getResponseItems = httpsCallable(functions, 'getResponseItemsRPC')

    // when using the preview
    if (tenantID === 'preview') {
      console.log('not querying Responses for preview')
      return []
    }

    const gerResponseItemsRCP: RPCGetResponseItemsRequest = {
      moduleType: this.type,
      tenantID,
      asidID,
      elementID,
      responseID
    }

    return getResponseItems(gerResponseItemsRCP).then((response) => {
      console.log(response)
      const appData = response.data as RPCGetResponseItemsResponse

      return appData.items as T[]
    })
  }

  /**
   *
   * @param moduleType
   * @param elementID
   * @param tenantID
   * @param responseIDs array to get specific responses, or empty array to get all responses
   * @returns
   */
  protected static async getResponsesHelper<T extends BaseResponseAppData>(
    elementID: objectID,
    tenantID: TenantID,
    asidID: asidID,
    responseIDs: objectID[] = [],
    responseTransactionIDs: objectID[] = []
  ) {
    const getResponses = httpsCallable(functions, 'getResponsesRPC')

    // when using the preview
    if (tenantID === 'preview') {
      console.log('not querying Responses for preview')
      return []
    }

    const gerResponsesRCP: RPCGetResponsesRequest = {
      moduleType: this.type,
      tenantID,
      elementID,
      asidID,
      responseIDs,
      responseTransactionIDs
    }

    console.log('getResponsesHelper', gerResponsesRCP)

    return getResponses(gerResponsesRCP).then((response) => {
      console.log(response)
      const appData = response.data as RPCGetResponsesResponse

      return appData.responses as T[]
    })
  }

  public static getRoutes(): RouteConfig[] {
    return []
  }

  public static registerOverviewVue(): VueImport { }

  public static registerBackgroundVue(): VueImport { }
}
