import dayjs from 'dayjs'
import platform from 'platform'
import { copy } from './clipboardHelper'

export function getDebugInformation(params: { [paramName: string]: string | number }) {
  const { tenantUrl, ...restParams } = params

  return (
    '### ECHO PRM debug information\n'
    + '**general**\n'
    + `- date: ${dayjs(Date.now()).format('YYYY.MM.DD - HH:mm')}\n`
    + `- url: ${tenantUrl || window.location} - (${process.env.VUE_APP_FIREBASE_ENV}) \n`
    + '\n'
    + '**system info**\n'
    // `- browser: ${platform.name}\n` +
    // `- os: ${platform.os?.family} ${platform.os?.version}\n` +
    + `- platform: ${platform.description}\n`
    + `- ua: ${platform.ua}\n`
    + '\n'
    + '**parameters**\n'
    + Object.entries(restParams)
      .map(([paramName, value]) => `- ${paramName}: ${value}`)
      .join('\n')
  )
}

/**
 *
 * @param params object of params added to the formatted output
 */
export function copyDebugInformationToClipboard(params: { [paramName: string]: string | number } = {}) {
  const debugInfoString = getDebugInformation(params)
  return copy(debugInfoString, {
    debug: true
  })
}
