import { asidID } from '@/types/typeAsid'
import { functions as firebaseFunctions } from '@/firebaseApp'
import { RPCAppDataResponse, RPCGenericRequest, RPCGenericResponse } from '@/types/typeRPC'
import { getLocalString } from '@/helpers/i18nUtil'
import { I18nGlobalsInst } from '../_globals/i18nGlobals'
import { LocalizedField } from '@/types/typeI18n'
import { httpsCallable } from 'firebase/functions'
import ModuleManagerApp from '../moduleManagerApp'
import Event from '@/helpers/eventBus'


interface typeEventResponseCreated {
  response: any
  elementID: string
  asidID: string
  moduleType: string
  responseSession: string
  locale: string
}

export default class CustomScriptExecution {
  public static eventOnScriptExecutionLog = new Event<{ logData: any[], instanceID: string }>('onScriptExecutionLog', 'CustomScriptExecution')

  public static eventPageChange = new Event<string>('pageChange', 'CustomScriptExecution')
  public static eventFormSent = new Event<{ elementID: string, response: any }>('formSent', 'CustomScriptExecution')
  public static eventLocaleChange = new Event<string[]>('localeChange', 'CustomScriptExecution')
  public static eventResponseCreated = new Event<typeEventResponseCreated>('responseCreated', 'CustomScriptExecution')

  public static resetEventBus(instanceID?: string) {
    this.eventOnScriptExecutionLog.unsubscribeAll(instanceID)

    this.eventPageChange.unsubscribeAll(instanceID)
    this.eventFormSent.unsubscribeAll(instanceID)
    this.eventLocaleChange.unsubscribeAll(instanceID)
    this.eventResponseCreated.unsubscribeAll(instanceID)
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private static instances: { [key: string]: Function } = {}

  public static execute(
    codeString: string,
    ctx: {
      asid: asidID
      identifiers: { [key: string]: string | number }
      directCategoryNames: string[]
      allCategoryNames: string[]
      backend: boolean
    },
    functions: {
      addResponse: (data: any) => Promise<any> // keys starting with '_' are not shown in the tabular view
      hideLoading: () => void
      showLoading: () => void
    },
    window: Window,
    instanceID: string, // is used to make sure that upon recurring invocation each function instance only exists once
    tenantID: string,
    $i18n: typeof I18nGlobalsInst,
    appData: RPCAppDataResponse,
    $widgetElement?: Element
  ) {
    const events = {
      onPageChanged: (cb: (data: {
        path: string
        type: 'page' | 'widget' | 'legal'
        pageName: string
        pageID: string
        widgetName: string
        widgetID: string
      }) => void, invokeWithPastEvents = true) => {
        this.eventPageChange.on(
          {
            cb:
              (path: string) => {
                if (!ctx.backend) {
                  let widgetTitle = ''
                  let widgetID = ''
                  let pageName = ''
                  let pageID = ''

                  const { type, tuples } = ModuleManagerApp.getRouteTuples(appData, path)

                  if (type === 'widget') {
                    widgetTitle = $i18n.getLocalString(tuples[0].moduleAppData.group.title)
                    widgetID = tuples[0].moduleAppData.group.id
                  }

                  if (type === 'page') {
                    pageName = path.split('/').pop() || ''
                    pageID = path.split('/').pop() || ''
                  }

                  cb({
                    path,
                    type,
                    pageName,
                    pageID,
                    widgetName: widgetTitle,
                    widgetID
                  })
                } else {
                  // cb({
                  //   path: path,
                  //   type: 'page',
                  //   pageName: path,
                  //   pageID: path,
                  //   widgetName: '',
                  //   widgetID: ''
                  // })
                }
              },
            subscriptionGroupID: instanceID,
            invokeWithPastEvents
          })
      },

      onFormSent: (cb: (data: any) => void, invokeWithPastEvents = true) =>
        this.eventFormSent.on({ cb, subscriptionGroupID: instanceID, invokeWithPastEvents }),

      onLocaleChanged: (cb: (locale: string[]) => void, invokeWithPastEvents = true) =>
        this.eventLocaleChange.on({ cb, subscriptionGroupID: instanceID, invokeWithPastEvents }),

      onResponseCreated: (cb: (data: typeEventResponseCreated) => void, invokeWithPastEvents = true) =>
        this.eventResponseCreated.on({ cb, subscriptionGroupID: instanceID, invokeWithPastEvents })
    }

    const loadJs = (url: string) => {
      return new Promise<void>((resolve, reject) => {
        const scriptTag = document.createElement('script')
        scriptTag.src = url

        scriptTag.onload = () => {
          this.eventOnScriptExecutionLog.emit({ logData: ['loadJs:loaded', url], instanceID })
          resolve()
        }
        // scriptTag.onreadystatechange = callback

        document.body.appendChild(scriptTag)
      })
    }

    const loadCss = (url: string) => {
      return new Promise<void>((resolve, reject) => {
        const link = document.createElement('link')

        link.rel = 'stylesheet'
        link.href = url

        link.onload = () => {
          this.eventOnScriptExecutionLog.emit({ logData: ['loadCss:loaded', url], instanceID })
          resolve()
        }

        document.head.appendChild(link)
      })
    }

    // callRPC: (rpcName: string, data: any) => Promise<any>
    const callRPC = async (rpcName: string, data: any): Promise<RPCGenericResponse> => {
      const genericRequest = httpsCallable(firebaseFunctions, 'genericRequestRPC')

      const rpcData: RPCGenericRequest = {
        asidID: ctx.asid,
        tenantID: tenantID,
        functionName: rpcName,
        data: data
      }

      const result = (await genericRequest(rpcData)) as RPCGenericResponse

      this.eventOnScriptExecutionLog.emit({ logData: ['callRPC', rpcName, data, result], instanceID })

      return result
    }

    // wrapper around the fetch api
    const proxyFetch = async (url: string, options: RequestInit = {}, proxyID = '') => {
      const cloudFunctionUrl = getProxyUrl(url, proxyID)

      const response = await fetch(cloudFunctionUrl, {
        ...options,
        headers: {
          ...(options.headers || {})
        }
      })

      this.eventOnScriptExecutionLog.emit({ logData: ['proxyFetch', url, options, response], instanceID })

      return response
    }

    // return a url which is processed by the proxy. Used e.g. for image src. E.g. url: https://customdomain.com/image.png => https://cloudfunction.com/appHttpProxy/image.png?echo-proxy-id=customdomain.com&echo-proxy-tenant=tenantID
    const getProxyUrl = (url: string, proxyID = '') => {
      const urlObject = new URL(url)
      const domainAndProtocol = urlObject.protocol + '//' + urlObject.hostname
      const pathAndQuery = urlObject.pathname + urlObject.search

      const proxyUrlParams = {
        'echo-proxy-id': proxyID || domainAndProtocol,
        'echo-proxy-tenant': tenantID
      }

      const cloudFunctionUrl = process.env.VUE_APP_FIREBASE_CLOUD_FUNCTION_BASE_DOMAIN + '/appHttpProxy' + pathAndQuery

      const proxyUrl = new URL(cloudFunctionUrl)
      Object.entries(proxyUrlParams).forEach(([key, value]) => proxyUrl.searchParams.append(key, value))

      return proxyUrl.toString()
    }

    const getLocalizedString = (localizedText: { [key: string]: string }, cb: (text: string) => void) => {
      this.eventLocaleChange.on({
        cb:
          (locale) => {
            const localizedField: LocalizedField = { _ltType: true, locales: localizedText }
            // not sure if acessing I18nGlobalsInst here is a good idea, as it created a dependency between the two modules
            cb(getLocalString(localizedField, $i18n.appActiveLocales, $i18n.fallbackMode))
          },
        subscriptionGroupID: instanceID,
        invokeWithPastEvents: true
      })
    }

    // todo this shall make sure a new instance is created whenever clicking 'execute' while developing the script
    // however this does not work, as the instacnes are still "alive" after removing them
    // => eventlistsners are still being fired. Workaround: change the html so the listeners are not bund to the same objects again?
    if (instanceID in this.instances) {
      this.resetEventBus(instanceID)
      delete this.instances[instanceID]
    }


    // wrapp all outgoing functions to log their execution
    const log = (...data: any[]) => {
      this.eventOnScriptExecutionLog.emit({ logData: data, instanceID })
      console.log(...data)
    }

    const addResponse = async (data: any) => {
      this.eventOnScriptExecutionLog.emit({ logData: ['addResponse', data], instanceID })
      return functions.addResponse(data)
    }

    const hideLoading = () => {
      this.eventOnScriptExecutionLog.emit({ logData: ['hideLoading'], instanceID })
      functions.hideLoading()
    }

    const showLoading = () => {
      this.eventOnScriptExecutionLog.emit({ logData: ['showLoading'], instanceID })
      functions.showLoading()
    }

    this.instances[instanceID] = new Function('ctx', 'events', 'functions', 'window', '$widgetElement', codeString)

    this.instances[instanceID](
      ctx,
      events,
      { hideLoading, showLoading, addResponse, log, loadJs, loadCss, callRPC, proxyFetch, getProxyUrl, getLocalizedString },
      window,
      $widgetElement
    )
  }

  public static validate(codeString: string) {
    return new Function('ctx', 'events', 'log', 'window', '$widgetElement', codeString)
  }
}
